import { cardStyle, cartesianGrid, pallet, tooltipStyle, tooltipTextStyle } from "./common.js";
const defaultLineChart = {
    properties: {
        style: {},
        component: {
            formatType: 'PERCENTAGE',
            cartesianGrid: cartesianGrid,
            pallet: pallet,
            tooltip: {
                style: tooltipStyle,
                textStyle: tooltipTextStyle
            },
            twoYAxes: false,
            metricSelection: true
        },
        cardStyle: cardStyle
    }
};
export const copyLineChartStyles = (properties) => {
    const _properties = { ...properties };
    return _properties;
};
export default defaultLineChart;
