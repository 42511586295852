import { Icon, IconButton } from '@chakra-ui/react';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

interface ShowInputProps {
    value: boolean;
    onChange: (value: boolean) => void;
}

const ShowInput: React.FC<ShowInputProps> = ({ value, onChange }) => {
    return (
        <IconButton
            aria-label="Show"
            icon={<Icon as={value ? IoMdEye : IoMdEyeOff} />}
            onClick={() => onChange(!Boolean(value))}
        />
    );
};

export default ShowInput;
