import { useHotkeys } from 'react-hotkeys-hook';
import {
    ActiveSelection,
    CanvasObjects,
    duplicateSelectedObjects,
    setActiveSelection,
    updateComponents
} from './types';

interface useCanvasHotkeysProps {
    enabledInlineEditor: boolean;
    canvasObjects: CanvasObjects;
    activeSelection: ActiveSelection;
    setActiveSelection: setActiveSelection;
    duplicateSelectedObjects: duplicateSelectedObjects;
    deleteSelectedObjects: duplicateSelectedObjects;
    updateComponents: updateComponents;
}

export const useCanvasHotkeys = ({
    enabledInlineEditor,
    canvasObjects,
    activeSelection,
    setActiveSelection,
    duplicateSelectedObjects,
    deleteSelectedObjects,
    updateComponents
}: useCanvasHotkeysProps) => {
    const isMinSelectedObjects = activeSelection.size > 0;

    useHotkeys('esc', () => setActiveSelection(new Set()), [
        canvasObjects,
        activeSelection
    ]);

    useHotkeys(
        'ctrl+d',
        duplicateSelectedObjects,
        {
            preventDefault: true,
            enabled: () => isMinSelectedObjects
        },
        [canvasObjects, activeSelection]
    );

    useHotkeys(
        ['delete', 'backspace'],
        deleteSelectedObjects,
        {
            preventDefault: true,
            enabled: () => isMinSelectedObjects && !enabledInlineEditor
        },
        [canvasObjects, activeSelection]
    );

    useHotkeys('ctrl+a', () => selectAllObjects(), { preventDefault: true });

    useHotkeys(
        'ctrl+]',
        () => bringForward(),
        { preventDefault: true, enabled: () => isMinSelectedObjects },
        [canvasObjects, activeSelection]
    );
    useHotkeys(
        'ctrl+[',
        () => sendBackward(),
        { preventDefault: true, enabled: () => isMinSelectedObjects },
        [canvasObjects, activeSelection]
    );
    useHotkeys(
        ']',
        () => bringFront(),
        {
            preventDefault: true,
            enabled: () => isMinSelectedObjects,
            ignoreEventWhen: (event) => event.ctrlKey
        },
        [canvasObjects, activeSelection]
    );
    useHotkeys(
        '[',
        () => sendBack(),
        {
            preventDefault: true,
            enabled: () => isMinSelectedObjects,
            ignoreEventWhen: (event) => event.ctrlKey
        },
        [canvasObjects, activeSelection]
    );

    const selectAllObjects = () => {
        canvasObjects.forEach((obj) => activeSelection.add(obj.id || ''));
        setActiveSelection(new Set(activeSelection));
    };

    const bringForward = () => {
        const selectedObjects = canvasObjects.filter((obj) =>
            activeSelection.has(obj.id)
        );

        const updatedComponents = selectedObjects.map((obj) => {
            return {
                ...obj,
                zIndex: obj.zIndex ? obj.zIndex + 1 : 1
            };
        });

        updateComponents(updatedComponents);
    };

    const sendBackward = () => {
        const selectedObjects = canvasObjects.filter((obj) =>
            activeSelection.has(obj.id)
        );

        const updatedComponents = selectedObjects.map((obj) => {
            return {
                ...obj,
                zIndex: obj.zIndex ? obj.zIndex - 1 : 0
            };
        });

        updateComponents(updatedComponents);
    };

    const bringFront = () => {
        const selectedObjects = canvasObjects.filter((obj) =>
            activeSelection.has(obj.id)
        );

        const updatedComponents = selectedObjects.map((obj) => {
            return {
                ...obj,
                zIndex: canvasObjects.length
            };
        });

        updateComponents(updatedComponents);
    };

    const sendBack = () => {
        const selectedObjects = canvasObjects.filter((obj) =>
            activeSelection.has(obj.id)
        );

        const updatedComponents = selectedObjects.map((obj) => {
            return {
                ...obj,
                zIndex: 0
            };
        });

        updateComponents(updatedComponents);
    };
};
