import {
    useGetClientQuery,
    useGetReportByIdQuery,
    useLazyGetReportBySlugQuery,
} from '@api';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Spinner } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '@hooks';
import { ClientTemplateParams } from '@router';
import { ReportPage } from 'common/types';
import { reportValidator } from 'common/validators';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Root } from 'src/pages/Root';
import {
    selectActiveReport,
    selectActiveReportPage,
    setActiveReportPageId
} from 'src/redux/features/blueprint/bluePrintSlice';
import { PathNames } from 'src/router/router';
import { z } from 'zod';
import CanvasProvider from '../../editor/EditorContext';
import Header from './components/Header';
import Sidebar from './components/Sidebar';

const createFromTemplate = reportValidator.createFromTemplate.omit({
    title: true,
    slug: true,
    password: true
});
type FormData = z.infer<typeof createFromTemplate>;

const extendedZodResolver = (
    validationSchema: z.ZodObject<any, any>,
    activeReportPages: ReportPage[] | undefined
) =>
    useCallback(
        async (data: any) => {
            const selectedActiveReportPages =
                activeReportPages?.filter((page: ReportPage) =>
                    data.pages.includes(page.id)
                ) ?? [];
            // Extract unique data sources from selected pages
            const unionDataSources = [
                ...new Set(selectedActiveReportPages.flatMap((page) => page.usedSources))
            ];
            // check if all data sources used in selected pages are connected
            const allConnected = unionDataSources.every(
                (dataSource) => data.connectedSources[dataSource]
            );
            let specificConnectedSourcesError: Record<string, any> = {};
            unionDataSources.forEach((dataSource) => {
                if (!data.connectedSources[dataSource]) {
                    specificConnectedSourcesError[dataSource] = {
                        type: `connectedSources.${dataSource}`,
                        message: 'This data source must be connected'
                    };
                }
            });

            try {
                const result = await validationSchema.parseAsync(data);
                let errors = {};
                if (!allConnected) {
                    errors = {
                        ...errors,
                        connectedSources: specificConnectedSourcesError
                    };
                }
                return {
                    values: result,
                    errors: errors
                };
            } catch (errors: any) {
                let schemaErrors = errors.errors.reduce(
                    (acc: Record<string, any>, error: any) => {
                        acc[error.path.join('.')] = {
                            type: error.message,
                            message: error.message
                        };
                        return acc;
                    },
                    {}
                );

                if (!allConnected) {
                    schemaErrors = {
                        ...schemaErrors,
                        connectedSources: specificConnectedSourcesError
                    };
                }
                return {
                    values: {},
                    errors: schemaErrors
                };
            }
        },
        [validationSchema, activeReportPages]
    );

const SelectedTemplate = () => {
    const params = useParams<ClientTemplateParams>();
    const navigate = useNavigate();
    const activeReport = useAppSelector(selectActiveReport);
    const activePage = useAppSelector(selectActiveReportPage);
    const [fetchReport] = useLazyGetReportBySlugQuery();
    const dispatch = useAppDispatch();
    const { data: client } = useGetClientQuery(
        { id: +params.clientId! },
        {
            skip: !params.clientId
        }
    );

    const { data: template } = useGetReportByIdQuery(
        {
            id: +params.templateId!
        },
        {
            skip: !params.templateId
        }
    );

    useEffect(() => {
        if (params.reportSlug && template?.clientId) {
            fetchReport({ slug: params.reportSlug, clientId: template.clientId });
        }
    }, [params.templateId, params.reportSlug, template]);

    useEffect(() => {
        if (activeReport?.pages) {
            const activePage = activeReport?.pages.find(
                (page) => page.slug === params.viewSlug
            );
            if (activePage) {
                dispatch(setActiveReportPageId(activePage.id));
            } else {
                dispatch(setActiveReportPageId(activeReport.pages[0].id));
            }
        }
    }, [activeReport?.pages, params.viewSlug]);

    const isOnePageReport = activeReport?.pages.length === 1;

    const methods = useForm<FormData>({
        mode: 'all',
        resolver: extendedZodResolver(createFromTemplate, activeReport?.pages),
        values: {
            clientId: +params?.clientId!,
            pages: activeReport?.pages?.map((page) => page.id) ?? [],
            templateId: template?.id!,
            connectedSources: {}
        },
        reValidateMode: 'onSubmit'
    });

    if (!client || !template) {
        return <Spinner />;
    }

    const pages = () => {
        if (Array.isArray(activeReport?.pageOrder)) {
            const pagesFormPageOrder = activeReport?.pageOrder
                .map((pageId) => activeReport?.pages?.find((page) => page.id === pageId))
                .filter((page): page is ReportPage => page !== undefined) ?? [];
            const leftOverPages = activeReport?.pages?.filter((page) => activeReport.pageOrder?.indexOf(page.id) === -1) ?? [];
            return [...pagesFormPageOrder, ...leftOverPages];
        } else {
            return activeReport?.pages ?? [];
        }
    }

    return (
        <>
            <FormProvider {...methods}>
                <Flex>
                    <Flex flexDir='column' width='20%'>
                        <Button
                            onClick={() =>
                                navigate(
                                    generatePath(PathNames.CLIENT_CREATE_REPORT_SIMPLE, {
                                        clientId: params.clientId!
                                    }), 
                                    { state: { from: location }}
                                )
                            }
                            leftIcon={<ChevronLeftIcon h={6} w={6} />}
                            display={'flex'}
                            alignItems={'center'}
                            textDecoration={'underline'}
                            width="fit-content"
                            border={'none'}
                            outline={'none'}
                            color={'brand.primary'}
                            _hover={{
                                color: 'black',
                                border: 'none'
                            }}
                        >
                            Back
                        </Button>
                        {!isOnePageReport && (
                            <Sidebar
                                pages={pages()}
                                clientConnectedSources={client?.connectedSources ?? []}
                                clientId={client.id}
                                template={template}
                            />
                        )}
                    </Flex>
                    <Flex flexDir='column' width='80%'>
                        <Header
                            pages={activeReport?.pages ?? []}
                            clientConnectedSources={client?.connectedSources ?? []}
                        />
                        <Box position='relative' zIndex={0} overflowX='scroll'>
                            <CanvasProvider
                                useSharedReportAPI={false}
                                useEditorReport={false}
                                reportBasePathName={generatePath(
                                    PathNames.CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE,
                                    {
                                        clientId: params.clientId!,
                                        templateId: params.templateId!
                                    }
                                )}
                            height={activePage?.layout?.pageHeight}
                                            width={activePage?.layout?.pageWidth}
                            >
                                {activePage && <Root />}
                            </CanvasProvider>
                        </Box>
                    </Flex>
                </Flex>
            </FormProvider>
        </>
    );
};

export default SelectedTemplate;
