import {
    Badge,
    Box,
    Button,
    Card,
    CardBody,
    Flex,
    Text,
    Tooltip
} from '@chakra-ui/react';
import { ColumnDef, createColumnHelper, Row, SortingFn } from '@tanstack/react-table';
import {
    BaseTable,
    DateTimeCell,
    PasswordCell,
    SourceIcon,
    StyledSelect,
    UrlCell
} from '@tasklogy/zircon-ui-components';
import { useMemo, useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import ModalWindow from 'src/components/ModalWindow';
import SearchInput from 'src/components/SearchComponent';
import { useListClientsQuery, useListReportsQuery } from 'src/redux/api';
import { Client, Report } from 'src/redux/types/api';
import { PathNames } from 'src/router/router';
import ReportName from './ReportName';

import { useModal } from 'src/hooks/useModal';
import ActionCell from './ActionCell';
import CreateForm from './CreateForm';
import { getEnv } from 'src/utils';
import SelectClientModal from './SelectClientModal';
import LoadingStatus from '../LoadingStatus';
import { useAuth } from 'src/auth/useAuth';

const columnHelper = createColumnHelper<Report>();

const globalSortingFn: SortingFn<Report> = (
    rowA: Row<Report>,
    rowB: Row<Report>,
    columnId: string
) => {
    if (columnId === 'client') {
        const clientA = rowA.original[columnId]?.name ?? '';
        const clientB = rowB.original[columnId]?.name ?? '';
        return clientA.localeCompare(clientB);
    }
    if (columnId === 'createdAt' || columnId === 'updatedAt') {
        const dateA = new Date(rowA.original[columnId]);
        const dateB = new Date(rowB.original[columnId]);
        return dateA.getTime() - dateB.getTime();
    }
    if (columnId === 'createdBy') {
        const createdByA = rowA.original[columnId]?.fullName ?? '';
        const createdByB = rowB.original[columnId]?.fullName ?? '';
        return createdByA.localeCompare(createdByB);
    }
    // isActive column, sort by active first, then draft, then disabled
    if (columnId === 'isActive') {
        if (rowA.original.isDraft && !rowB.original.isDraft) {
            return -1;
        }
        if (!rowA.original.isDraft && rowB.original.isDraft) {
            return 1;
        }
        if (!rowA.original.isActive && rowB.original.isActive) {
            return -1;
        }
        if (rowA.original.isActive && !rowB.original.isActive) {
            return 1;
        }
    }
    return 0;
};

const columns: ColumnDef<Report, any>[] = [
    columnHelper.accessor('id', {
        id: 'id',
        cell: (info) => info.getValue(),
        header: () => 'ID'
    }),
    columnHelper.accessor('title', {
        id: 'title',
        cell: (info) => {
            return (
                <ReportName
                    title={info.getValue()}
                    reportSlug={info.row.original.slug}
                    clientId={info.row.original.clientId}
                />
            );
        },
        footer: (info) => info.column.id,
        header: () => 'Title'
    }),
    columnHelper.accessor('client', {
        id: 'client',
        cell: (info) => {
            const client = info.getValue();
            if (client) {
                const clientDetailPath = generatePath(PathNames.CLIENT_DETAIL, {
                    clientId: client.id.toString()
                });
                const navigate = useNavigate();
                return (
                    <Text
                        _hover={{
                            cursor: 'pointer',
                            textDecoration: 'underline'
                        }}
                        onClick={() => navigate(clientDetailPath)}
                    >
                        {client.name}
                    </Text>
                );
            }
            return '-';
        },
        header: () => 'Client',
        sortingFn: globalSortingFn
    }),
    columnHelper.accessor('createdAt', {
        id: 'createdAt',
        cell: (info) => <DateTimeCell value={new Date(info.getValue())} />,
        header: () => 'Created at',
        sortingFn: globalSortingFn
    }),
    columnHelper.accessor('updatedAt', {
        id: 'updatedAt',
        cell: (info) => <DateTimeCell value={new Date(info.getValue())} />,
        header: () => 'Last edit',
        sortingFn: globalSortingFn
    }),

    columnHelper.accessor('slug', {
        id: 'previewUrl',
        cell: (info) => {
            const previewUrl =
                window.location.origin +
                generatePath(PathNames.SHARED_REPORT, {
                    clientId: info.row.original.clientId.toString(),
                    reportSlug: info.getValue(),
                    viewSlug: null
                });
            return <UrlCell value={previewUrl} />;
        },
        header: () => 'Report URL',
        enableSorting: false
    }),
    columnHelper.accessor('password', {
        id: 'password',
        cell: (info) => <PasswordCell password={info.getValue()} />,
        header: () => 'Password',
        enableSorting: false
    }),
    columnHelper.accessor('usedDataSources', {
        id: 'usedDataSources',
        cell: (info) => <SourceIcon selectedIcons={info.getValue() ?? []} />,
        header: () => 'Used data sources'
    }),
    columnHelper.accessor('isActive', {
        id: 'isActive',
        cell: (info) => {
            const isActive = info.getValue();
            let text = 'Active';
            let colorScheme = 'green';

            if (!isActive) {
                text = 'Disabled';
                colorScheme = 'gray';
            }

            if (info.row.original.isDraft) {
                text = 'Draft';
                colorScheme = 'yellow';
            }

            return (
                <Flex alignItems="baseline">
                    <Badge colorScheme={colorScheme} textAlign="center">
                        {text}
                    </Badge>
                </Flex>
            );
        },
        header: () => 'Status',
        sortingFn: globalSortingFn
    }),
    columnHelper.accessor('createdBy', {
        id: 'createdBy',
        cell: (info) => (info.getValue() ? info.getValue().fullName : '-'),
        header: () => 'Created by',
        sortingFn: globalSortingFn
    }),
    columnHelper.accessor('id', {
        id: 'action',
        cell: (info) => <ActionCell report={info.row.original} />,
        header: () => null
    })
];

interface Props {
    client?: Client;
    withClientFilter?: boolean;
}

const allClientsOption = {
    label: 'All clients',
    value: null
};

const ReportsTable = ({ client, withClientFilter = false }: Props) => {
    const location = useLocation();
    const [search, setSearch] = useState<string>('');
    const navigate = useNavigate();
    const { user } = useAuth();
    const { close, open, isOpen } = useModal();
    const [selectedClientId, setSelectedClientId] = useState<number | null>(null);
    const {
        close: onCloseCreateReport,
        open: openCreateReport,
        isOpen: isOpenCreateReport
    } = useModal();
    const { data: reports, isLoading: isLoadingReports } = useListReportsQuery(
        {
            teamId: user?.teamId,
            clientId: client?.id
        },
        {
            skip: !user
        }
    );

    const { data: clients } = useListClientsQuery(
        {
            teamId: user?.teamId ?? 0
        },
        {
            skip: !user?.teamId
        }
    );

    const filteredColumns = useMemo(() => {
        if (client) {
            return columns.filter((column) => column.id !== 'client');
        }

        return columns;
    }, [client]);

    const isCreateDisabled =
        client && client.connectedSources && client.connectedSources.length === 0;

    const reportsFiltered = useMemo(() => {
        let result: Report[] = reports ?? [];

        if (selectedClientId) {
            result = result?.filter((report) => report.clientId === selectedClientId);
        }

        if (search) {
            result = result?.filter(
                (report) =>
                    report.title.toLowerCase().includes(search.toLowerCase()) ||
                    (report.client?.name &&
                        report.client.name.toLowerCase().includes(search.toLowerCase()))
            );
        }

        return result;
    }, [reports, search, selectedClientId]);

    const clientsOptions =
        clients?.map((client) => ({
            label: client.name,
            value: client.id
        })) ?? [];

    return (
        <Box w="100%" textAlign="center">
            <LoadingStatus isLoading={isLoadingReports}>
                <Card>
                    <CardBody>
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            mb="1.5rem"
                            gap="20px"
                        >
                            <Text variant="subtitle">Reports</Text>
                            <Flex gap="1rem" alignItems="center">
                                {withClientFilter && (
                                    <Box w="15rem" textAlign="left">
                                        <StyledSelect
                                            options={[
                                                allClientsOption,
                                                ...clientsOptions
                                            ]}
                                            onChange={(option: any) =>
                                                setSelectedClientId(option.value)
                                            }
                                            defaultValue={allClientsOption}
                                            value={clientsOptions?.find(
                                                (option) =>
                                                    option.value === selectedClientId
                                            )}
                                        />
                                    </Box>
                                )}
                                <SearchInput search={search} setSearch={setSearch} />

                                {getEnv() === 'local' && false && (
                                    <Button
                                        variant="solid"
                                        isDisabled={isCreateDisabled}
                                        onClick={openCreateReport}
                                    >
                                        Create report (directly)
                                    </Button>
                                )}

                                <Tooltip
                                    label="This client has no connected account. Please connect at least one data source to create a report."
                                    aria-label="Create report"
                                    placement="top"
                                    isDisabled={!isCreateDisabled}
                                    hasArrow
                                >
                                    <Button
                                        variant="solid"
                                        isDisabled={isCreateDisabled}
                                        onClick={() => {
                                            if (client) {
                                                navigate(
                                                    generatePath(
                                                        PathNames.CLIENT_CREATE_REPORT_SIMPLE,
                                                        {
                                                            clientId: client.id.toString()
                                                        }
                                                    ),
                                                    { state: { from: location }}
                                                );
                                            } else {
                                                open();
                                            }
                                        }}
                                    >
                                        Create report
                                    </Button>
                                </Tooltip>
                            </Flex>
                        </Flex>
                        <BaseTable
                            variant="reports"
                            columns={filteredColumns as any}
                            data={reportsFiltered ?? []}
                        />
                    </CardBody>
                </Card>
                <ModalWindow
                    minWidth="50rem"
                    isOpen={isOpenCreateReport}
                    onClose={onCloseCreateReport}
                    title={'Create report'}
                >
                    <CreateForm onClose={onCloseCreateReport} />
                </ModalWindow>
                <SelectClientModal
                    clients={clients ?? []}
                    isOpen={isOpen}
                    onClose={close}
                    onClientSelect={(selectedClientId) => {
                        if (selectedClientId) {
                            navigate(
                                generatePath(PathNames.CLIENT_CREATE_REPORT_SIMPLE, {
                                    clientId: selectedClientId.toString()
                                })
                            );
                        }
                    }}
                />
            </LoadingStatus>
        </Box>
    );
};
export default ReportsTable;
