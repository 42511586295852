import { z } from 'zod';
const linearGradient = z.object({
    x1: z.string(),
    x2: z.string(),
    y1: z.string(),
    y2: z.string(),
    stops: z
        .array(z.object({
        stopColor: z.string(),
        offset: z.string(),
        stopOpacity: z.number()
    }))
        .min(2),
    legendSymbol: z.string(),
    legendSymbolFillColor: z.string(),
    legendSymbolTextColor: z.string()
});
const pallet = z.array(z.string());
const donutChartLegend = z.object({
    layout: z.string().optional(),
    itemsAlign: z.string().optional(),
    verticalAlign: z.string().optional(),
    align: z.string().optional(),
    height: z.number().optional(),
    symbol: z.string().optional(),
    fontSize: z.number().optional()
});
const cartesianGrid = z.object({
    stroke: z.string(),
    opacity: z.number(),
    strokeDasharray: z.string()
});
const cssProperties = z.object({
    background: z.string().optional(),
    width: z.string().optional(),
    height: z.string().optional(),
    padding: z.string().optional(),
    boxShadow: z.string().optional(),
    borderRadius: z.string().optional(),
    color: z.string().optional(),
    borderBottom: z.string().optional(),
    borderBottomColor: z.string().optional(),
    fontSize: z.string().optional(),
    lineHeight: z.string().optional(),
    fontFamily: z.string().optional(),
    fontWeight: z.coerce.number().optional(),
    border: z.string().optional(),
    backgroundSize: z.string().optional()
});
const cardStyle = z.object({
    background: cssProperties.shape.background.optional(),
    width: cssProperties.shape.width.optional(),
    height: cssProperties.shape.height.optional(),
    padding: cssProperties.shape.padding.optional(),
    boxShadow: cssProperties.shape.boxShadow.optional(),
    borderRadius: cssProperties.shape.borderRadius.optional()
});
const textStyle = z.object({
    color: cssProperties.shape.color.optional(),
    fontSize: cssProperties.shape.fontSize.optional(),
    lineHeight: cssProperties.shape.lineHeight.optional()
});
const compareStyle = z.object({
    upColor: z.string(),
    downColor: z.string(),
    fontSize: cssProperties.shape.fontSize.optional(),
    fontFamily: cssProperties.shape.fontFamily.optional(),
    fontWeight: cssProperties.shape.fontWeight.optional()
});
export const donutChart = z.object({
    properties: z.object({
        style: z.object({}),
        cardStyle: cardStyle,
        component: z.object({
            label: z.string().optional(),
            pallet: pallet.optional(),
            legend: donutChartLegend.optional(),
            linearGradients: z.array(linearGradient).optional(),
            tooltip: z.object({
                style: cardStyle.optional(),
                textStyle: textStyle.optional()
            }).optional()
        }),
        labelStyle: textStyle
    })
});
export const table = z.object({
    properties: z.object({
        style: z.object({}),
        cell: z.object({
            color: cssProperties.shape.color.optional(),
            background: cssProperties.shape.background.optional()
        }),
        headerStyle: z.object({
            tr: z.object({
                background: cssProperties.shape.background.optional()
            }),
            th: z.object({
                color: cssProperties.shape.color.optional()
            }).optional(),
        }),
        headerCell: z.object({
            color: cssProperties.shape.color.optional()
        }),
        bodyStyle: z.object({
            tr: z.object({
                _odd: z.object({
                    background: cssProperties.shape.background.optional()
                }),
                _even: z.object({
                    background: cssProperties.shape.background.optional()
                }),
                borderBottom: cssProperties.shape.borderBottom.optional(),
                borderBottomColor: cssProperties.shape.borderBottomColor.optional()
            }),
            td: z.object({
                color: cssProperties.shape.color.optional()
            }).optional(),
        }),
        footerStyle: z.object({
            td: z.object({
                color: cssProperties.shape.color.optional()
            }),
            tr: z.object({
                background: cssProperties.shape.background.optional()
            }),
            cell: z.object({
                color: cssProperties.shape.color.optional()
            })
        }),
        component: z.object({
            paginationLayout: z.string().optional(),
            rowNumbers: z.boolean().optional(),
            pageSize: z.number().optional(),
        }),
        paginationButtonsStyle: z.object({
            rowsCount: z.object({
                color: cssProperties.shape.color.optional()
            }),
            backButton: z.object({
                _hover: z.object({
                    backgroundColor: cssProperties.shape.background.optional()
                }),
                backgroundColor: cssProperties.shape.background.optional()
            }),
            forwardButton: z.object({
                _hover: z.object({
                    backgroundColor: cssProperties.shape.background.optional()
                }),
                backgroundColor: cssProperties.shape.background.optional()
            }),
            iconBackButton: z.object({
                _hover: z.object({
                    color: cssProperties.shape.color.optional()
                }),
                color: cssProperties.shape.color.optional()
            }),
            iconForwardButton: z.object({
                _hover: z.object({
                    color: cssProperties.shape.color.optional()
                }),
                color: cssProperties.shape.color.optional()
            })
        }),
        paginationRowsPerPageStyle: z.object({
            labelStyle: textStyle,
            label: z.string().optional()
        }),
        compareStyle: compareStyle,
        cardStyle: cardStyle,
        emptyTableStyle: z.object({
            td: z.object({
                color: cssProperties.shape.color.optional()
            }).optional(),
            tr: z.object({
                background: cssProperties.shape.background.optional()
            }).optional(),
            cell: z.object({
                color: cssProperties.shape.color.optional()
            }).optional(),
            text: z.object({
                color: cssProperties.shape.color.optional()
            }).optional()
        }).optional(),
        idCell: z.object({
            color: cssProperties.shape.color.optional()
        }).optional(),
        modalOverlayStyle: z.object({
            background: cssProperties.shape.background.optional()
        }).optional(),
        modalContentStyle: z.object({
            background: cssProperties.shape.background.optional()
        }).optional(),
        modalBodyStyle: z.object({
            background: cssProperties.shape.background.optional()
        }).optional(),
    })
});
export const tinyBarChart = z.object({
    properties: z.object({
        style: z.object({}),
        component: z.object({
            pallet: pallet.optional(),
            linearGradients: z.array(linearGradient).optional(),
            cartesianGrid: cartesianGrid,
            tooltip: z.object({
                style: cardStyle.optional(),
                textStyle: textStyle.optional()
            }).optional()
        }),
        cardStyle: cardStyle
    })
});
export const biaxialBarChart = z.object({
    properties: z.object({
        style: z.object({}),
        component: z.object({
            formatType: z.string(),
            cartesianGrid: cartesianGrid,
            pallet: pallet,
            linearGradients: z.array(linearGradient).optional(),
            tooltip: z.object({
                style: cardStyle.optional(),
                textStyle: textStyle.optional()
            }).optional()
        }),
        cardStyle: cardStyle
    })
});
export const stackedBarChart = z.object({
    properties: z.object({
        style: z.object({}),
        component: z.object({
            formatType: z.string(),
            cartesianGrid: cartesianGrid,
            pallet: pallet,
            linearGradients: z.array(linearGradient).optional(),
            tooltip: z.object({
                style: cardStyle.optional(),
                textStyle: textStyle.optional()
            }).optional()
        }),
        cardStyle: cardStyle
    })
});
export const lineChart = z.object({
    properties: z.object({
        style: z.object({}),
        component: z.object({
            formatType: z.string(),
            cartesianGrid: cartesianGrid,
            pallet: pallet,
            linearGradients: z.array(linearGradient).optional(),
            tooltip: z.object({
                style: cardStyle.optional(),
                textStyle: textStyle.optional()
            }).optional(),
            twoYAxes: z.boolean().optional(),
            metricSelection: z.boolean().optional()
        }),
        cardStyle: cardStyle
    })
});
export const areaChart = z.object({
    properties: z.object({
        style: z.object({}),
        component: z.object({
            formatType: z.string(),
            cartesianGrid: cartesianGrid,
            pallet: pallet,
            linearGradients: z.array(linearGradient).optional(),
            tooltip: z.object({
                style: cardStyle.optional(),
                textStyle: textStyle.optional()
            }).optional()
        }),
        cardStyle: cardStyle
    })
});
export const singleValue = z.object({
    properties: z.object({
        style: z.object({}),
        cardStyle: cardStyle,
        label: textStyle,
        value: textStyle,
        compareStyle: compareStyle
    })
});
const controlStyle = z.object({
    color: z.string().optional(),
    backgroundColor: z.string().optional(),
    flexWrap: z.string().optional(),
    textWrap: z.string().optional(),
    _hover: z
        .object({
        borderColor: z.string().optional()
    })
        .optional()
});
const dropdownIndicatorStyle = z.object({
    color: z.string().optional()
});
const indicatorSeparatorStyle = z.object({
    backgroundColor: z.string().optional()
});
const placeholderStyle = z.object({
    color: z.string().optional()
});
export const singleSelect = z.object({
    properties: z.object({
        style: z.object({}),
        label: z.string().optional(),
        controlStyle: controlStyle.optional(),
        dropdownIndicatorStyle: dropdownIndicatorStyle.optional(),
        indicatorSeparatorStyle: indicatorSeparatorStyle.optional(),
        placeholderStyle: placeholderStyle.optional()
    })
});
export const multiSelect = z.object({
    properties: z.object({
        style: z.object({}),
        label: z.string().optional(),
        controlStyle: controlStyle.optional(),
        dropdownIndicatorStyle: dropdownIndicatorStyle.optional(),
        indicatorSeparatorStyle: indicatorSeparatorStyle.optional(),
        placeholderStyle: placeholderStyle.optional()
    })
});
export const dateRange = z.object({
    properties: z.object({
        style: z.object({}),
        buttonStyle: z
            .object({
            color: z.string().optional(),
            _hover: z
                .object({
                borderColor: z.string().optional(),
                backgroundColor: z.string().optional()
            })
                .optional(),
            backgroundColor: z.string().optional(),
            borderColor: z.string().optional()
        })
            .optional(),
        pickerStyle: z
            .object({
            color: z.string().optional()
        })
            .optional(),
        omitPickerStyle: z
            .object({
            color: z.string().optional()
        })
            .optional(),
        submitButtonStyle: z
            .object({
            color: z.string().optional(),
            _hover: z
                .object({
                backgroundColor: z.string().optional()
            })
                .optional(),
            backgroundColor: z.string().optional()
        })
            .optional(),
        popoverContentStyle: z
            .object({
            color: z.string().optional(),
            borderColor: z.string().optional()
        })
            .optional(),
        staticRangeLabelStyle: z
            .object({
            color: z.string().optional()
        })
            .optional(),
        rangeColors: z.array(z.string()).optional(),
        label: z.string().optional().nullable(),
        text: z.string().optional(),
        showDropdownIndicator: z.boolean().optional(),
        showCalendarIcon: z.boolean().optional(),
        iconStyle: z
            .object({
            color: z.string().optional()
        })
            .optional(),
        labelStyle: textStyle.optional()
    })
});
export const DownloadIconAlign = z.enum(['left', 'right']);
export const downloadButton = z.object({
    properties: z.object({
        style: z.object({
            color: cssProperties.shape.color.optional(),
            background: cssProperties.shape.background.optional()
        }),
        component: z.object({
            label: z.string().optional(),
            loadingLabel: z.string().optional(),
            showIcon: z.boolean().optional()
        }),
        downloadIconStyle: z.object({
            color: cssProperties.shape.color.optional()
        }),
        loadingStyle: z.object({
            color: cssProperties.shape.color.optional(),
            background: cssProperties.shape.background.optional()
        }),
        downloadIconAlign: DownloadIconAlign
    })
});
export const text = z.object({
    properties: z.object({
        style: z.object({
            color: cssProperties.shape.color.optional(),
            fontSize: cssProperties.shape.fontSize.optional(),
            fontFamily: cssProperties.shape.fontFamily.optional(),
            fontWeight: cssProperties.shape.fontWeight.optional(),
            background: cssProperties.shape.background.optional()
        }),
        text: z.string().optional()
    })
});
export const horizontalAlign = z.enum(['left', 'center', 'right']);
export const verticalAlign = z.enum(['top', 'middle', 'bottom']);
export const navigationButton = z.object({
    properties: z.object({
        style: z.object({
            background: cssProperties.shape.background.optional(),
            color: cssProperties.shape.color.optional(),
            verticalAlign: verticalAlign,
            horizontalAlign: horizontalAlign
        }),
        textStyle: z.object({
            background: cssProperties.shape.background.optional(),
            color: cssProperties.shape.color.optional(),
            fontSize: cssProperties.shape.fontSize.optional(),
            fontWeight: cssProperties.shape.fontWeight.optional(),
            fontFamily: cssProperties.shape.fontFamily.optional(),
            _active: z.object({
                background: cssProperties.shape.background.optional(),
                color: cssProperties.shape.color.optional(),
                fontSize: cssProperties.shape.fontSize.optional(),
                fontWeight: cssProperties.shape.fontWeight.optional(),
                fontFamily: cssProperties.shape.fontFamily.optional()
            })
        })
    })
});
export const rectangle = z.object({
    properties: z.object({
        style: z.object({
            background: cssProperties.shape.background.optional(),
            border: cssProperties.shape.border.optional(),
            boxShadow: cssProperties.shape.boxShadow.optional(),
            backgroundSize: cssProperties.shape.backgroundSize.optional()
        })
    })
});
export const navbar = z.object({
    properties: z.object({
        style: z.object({
            background: cssProperties.shape.background.optional()
        }),
        buttonStyle: z
            .object({
            background: cssProperties.shape.background.optional(),
            color: cssProperties.shape.color.optional(),
            _active: z
                .object({
                background: cssProperties.shape.background.optional(),
                color: cssProperties.shape.color.optional(),
                fontSize: cssProperties.shape.fontSize.optional(),
                fontWeight: cssProperties.shape.fontWeight.optional(),
                fontFamily: cssProperties.shape.fontFamily.optional()
            })
                .optional(),
            _hover: z
                .object({
                color: cssProperties.shape.color.optional(),
                fontSize: cssProperties.shape.fontSize.optional(),
                fontWeight: cssProperties.shape.fontWeight.optional(),
                fontFamily: cssProperties.shape.fontFamily.optional()
            })
                .optional()
        })
            .optional(),
        containerStyle: z
            .object({
            background: cssProperties.shape.background.optional(),
            color: cssProperties.shape.color.optional()
        })
            .optional(),
        buttonContainer: z.object({
            background: cssProperties.shape.background.optional(),
            border: cssProperties.shape.border.optional(),
            borderColor: cssProperties.shape.border.optional(),
            verticalAlign: verticalAlign,
            horizontalAlign: horizontalAlign,
            _active: z
                .object({
                background: cssProperties.shape.background.optional(),
                border: cssProperties.shape.border.optional(),
                borderColor: cssProperties.shape.border.optional()
            })
                .optional(),
            _hover: z
                .object({
                background: cssProperties.shape.background.optional(),
                border: cssProperties.shape.border.optional(),
                borderColor: cssProperties.shape.border.optional()
            })
                .optional()
        })
    })
});
