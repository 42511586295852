import { Flex, Text } from '@chakra-ui/react';
import { Controller, FieldValues, Path } from 'react-hook-form';
import ShowInput from '../inputs/ShowInput';
import FormPropertyRow from '../layout/FormPropertyRow';

const ToggleForm = <T extends FieldValues>({
    name,
    title
}: {
    name: string;
    title: string;
}) => {
    return (
        <FormPropertyRow>
            <Flex
                alignItems={'center'}
                justifyContent="space-between"
                gap={'0.5rem'}
                width={'100%'}
            >
                <Text textStyle="_h2">{title ?? 'Show'}</Text>
                <Controller
                    name={name as Path<T>}
                    render={({ field }) => (
                        <ShowInput
                            value={Boolean(field.value)}
                            onChange={(value) => field.onChange(value)}
                        />
                    )}
                />
            </Flex>
        </FormPropertyRow>
    );
};

export default ToggleForm;
