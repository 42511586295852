import { useEffect, useState } from "react";
import { generatePath, Location, matchPath, matchRoutes } from "react-router-dom";
import { PathNames } from "src/router/router";

export const getEditorCanvasScrollY = () => {
    return Number(document.getElementById('editor-canvas')?.scrollTop) === 0 ? 0 : Number(document.getElementById('editor-canvas')?.scrollTop) + 20;
}

export const getEditorCanvasScrollX = () => {
    return document.getElementById('editor-canvas')?.scrollLeft || 0;
}

export const getElementByIdOffsetY = (id: string) => {
    return document.getElementById(id)?.offsetTop || 0;
}

// hook to get canvas x, y scroll
export const useCanvasScroll = () => {
    const [scrollX, setScrollX] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [canvasHeight, setCanvasHeight] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollX(document.getElementById('editor-canvas')?.scrollLeft || 0);
            setScrollY(document.getElementById('editor-canvas')?.scrollTop || 0);
            setCanvasWidth(document.getElementById('editor-canvas')?.clientWidth || 0);
            setCanvasHeight(document.getElementById('editor-canvas')?.clientHeight || 0);
        }
        document.getElementById('editor-canvas')?.addEventListener('scroll', handleScroll);
        return () => {
            document.getElementById('editor-canvas')?.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return { scrollX, scrollY, canvasWidth, canvasHeight };
}

// Find first pathname that does not match editor
export const findNonEditorPathname = (location: Location) => {
    let currentLocation = location;

    while (currentLocation) {
        // Check if the current location is not the editor
        if (!matchPath(PathNames.EDITOR, currentLocation.pathname)) {
            const checkInRoutes = [
                {path: PathNames.TEMPLATES},
                {path: PathNames.TEAM_CLIENT_DETAIL},
                {path: PathNames.CLIENT_DETAIL},
                {path: PathNames.DASHBOARD_REPORTS},
                {path: PathNames.CLIENT_CREATE_REPORT_SIMPLE},
                {path: PathNames.CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE},
                {path: PathNames.CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE_REPORT_VIEW}
            ]
            const matches = matchRoutes(checkInRoutes, currentLocation);

            if (!matches) {
                return PathNames.DASHBOARD_REPORTS;
            }

            const returnPaths = matches.map((match) => {
                if (match.pathnameBase === currentLocation.pathname){
                    const isClientCreateReportSimpleTemplateReportView = matchPath(PathNames.CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE_REPORT_VIEW, currentLocation.pathname);
                    const isClientCreateReportSimple = matchPath(PathNames.CLIENT_CREATE_REPORT_SIMPLE, currentLocation.pathname);
                    const isClientCreateReportSimpleTemplate = matchPath(PathNames.CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE, currentLocation.pathname);
                    if (isClientCreateReportSimpleTemplateReportView || isClientCreateReportSimple || isClientCreateReportSimpleTemplate) {
                        const previousLocation = currentLocation.state ? currentLocation.state.from : null;
                        if (previousLocation){
                            return `${previousLocation.pathname}${previousLocation.search}`
                        }
                        if (match.params.clientId) {
                            return `${generatePath(PathNames.CLIENT_DETAIL, {clientId: match.params.clientId})}?tab=reports`;
                        }
                        return PathNames.DASHBOARD_REPORTS;
                    }
                    return `${currentLocation.pathname}${currentLocation.search}`;
                }
                return null;
            }).filter(Boolean);

            return returnPaths.length ? returnPaths[0] : PathNames.DASHBOARD_REPORTS;
        }

        currentLocation = currentLocation.state ? currentLocation.state.from : null;
    }

    return null;
};