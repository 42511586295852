import { Box, Flex, Text } from '@chakra-ui/react';
import { ColumnFormatType } from 'common/enums';
import { DateTime } from 'luxon';
import { formatGenericValue } from 'src/utils';
import { useGradientPalletColor } from './utils';

export const renderTooltipContent = ({
    payload,
    fieldConfigs,
    properties,
    xAxisKey
}: any) => {
    if (!payload || !payload.length) return null;

    const xAxisFieldConfig = fieldConfigs[xAxisKey];
    const xValue = payload[0]?.payload?.[xAxisKey];
    let xValueText =
        xValue && xAxisFieldConfig?.type === ColumnFormatType.WEEK ? (
            <Text>
                Week {DateTime.fromISO(xValue).toFormat('dd.MM.yyyy')} -{' '}
                {DateTime.fromISO(xValue).plus({ days: 6 }).toFormat('dd.MM.yyyy')}
            </Text>
        ) : null;

    if (xValue && xAxisFieldConfig?.type === ColumnFormatType.DATE) {
        xValueText = <Text>{DateTime.fromISO(xValue).toFormat('d MMM yyyy')}</Text>;
    }

    if (payload.length === 1) {
        const { stroke } = useGradientPalletColor(properties, 0, 'stacked-bar-chart');
        return (
            <Box
                fontSize="12px"
                className="customized-tooltip-content"
                style={properties?.component?.tooltip?.style}
            >
                {xValueText}
                <Flex alignItems='center' gap='0.75rem'>
                    <Box
                        w="12px"
                        h="12px"
                        backgroundColor={stroke}
                        borderRadius="4px"
                    />
                    <Text style={properties?.component?.tooltip?.textStyle}>{`${
                        fieldConfigs[payload[0].name]?.title
                    }: ${formatGenericValue(
                        payload[0].value,
                        fieldConfigs[payload[0].name]?.type,
                        fieldConfigs[payload[0].name]
                    )}`}</Text>
                </Flex>
            </Box>
        );
    }

    return (
        <Box
            className="customized-tooltip-content"
            fontSize="12px"
            style={properties?.component?.tooltip?.style}
        >
            {xValueText}
            <ul className="list" style={{ listStyleType: 'none' }}>
                {payload.map((item: any, index: number) => {
                    const { stroke } = useGradientPalletColor(
                        properties,
                        index,
                        'stacked-bar-chart'
                    );
                    return (
                        <li
                            key={`item-${index}`}
                            style={{
                                ...properties?.component?.tooltip?.textStyle,
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.75rem'
                            }}
                        >
                            <Box
                                w="12px"
                                h="12px"
                                backgroundColor={stroke}
                                borderRadius="4px"
                            />
                            {`${fieldConfigs?.[item.name]?.title ?? item.name}: ${formatGenericValue(
                                item.value,
                                fieldConfigs[item.name]?.type
                            )}`}
                        </li>
                    );
                })}
            </ul>
        </Box>
    );
};
