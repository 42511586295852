import { Box, Flex, Text } from '@chakra-ui/react';
import { defaultTheme } from 'common/constants';
import { ComponentType } from 'common/enums';
import { formatGenericValue } from 'src/utils';

export const renderPieTooltipContent = ({ payload, fieldConfigs, properties }: any) => {
    if (!payload || !payload.length) return null;

    let fieldMetric = '';
    if (fieldConfigs) {
        Object.keys(fieldConfigs).forEach((key) => {
            if (fieldConfigs[key]?.isMetric) {
                fieldMetric = key;
            }
        });
    }

    const defaultTooltipStyle =
        defaultTheme.components[ComponentType.PIE_CHART]?.properties?.component?.tooltip
            ?.style;

    return (
        <Box
            fontSize="12px"
            className="customized-tooltip-content"
            style={{
                ...defaultTooltipStyle,
                ...properties?.component?.tooltip?.style
            }}
        >
            <Flex alignItems="center" gap="0.75rem">
                <Box w="12px" h="12px" backgroundColor={payload[0].payload.fill} borderRadius="4px" />
                <Text style={properties?.component?.tooltip?.textStyle}>{`${
                    payload[0].name
                }: ${formatGenericValue(
                    payload[0].value,
                    fieldConfigs?.[fieldMetric]?.type,
                    fieldConfigs?.[fieldMetric]
                )}`}</Text>
            </Flex>
        </Box>
    );
};
