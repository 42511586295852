import { Box, Flex, Text } from '@chakra-ui/react';
import { ComponentUI } from '@types';
import { useAppSelector } from 'src/hooks/redux';
import {
    selectComponent,
    selectComponentData
} from 'src/redux/features/blueprint/bluePrintSlice';
import { GenericTooltip } from 'src/templates/blueprint/organisms/GenericTooltip';
import { useDefaultData } from '../defaultData';
import { ComponentType } from 'common/enums';
import { formatGenericValue } from 'src/utils';
import { useMemo } from 'react';
import { DataComponent } from 'common/types';

const SingleValue = ({ properties, id }: ComponentUI) => {
    const componentData = useAppSelector((state) => selectComponentData(state, id));
    const defaultData = useDefaultData(ComponentType.SINGLE_VALUE);
    const component = useAppSelector((state) =>
        selectComponent(state, id)
    ) as DataComponent;

    let data = componentData?.data;
    if (componentData?.error || componentData === undefined) {
        data = defaultData;
    }

    const fieldConfig = data?.fieldConfigs?.[data?.valueKey];
    const compareFieldConfig = useMemo(() => {
        const valueKey = data?.valueKey;
        const compareColumns = data?.fieldConfigs?.[valueKey]?.compareColumns ?? {};
        return compareColumns?.delta;
    }, [data?.compare]);

    const labelStyle = {
        width: '100%',
        ...properties.label
    };

    const valueStyle = {
        width: '100%',
        ...properties.value
    };

    let compareColor = '#515A66';
    if (data?.compare) {
        if (data.compare.delta > 0) {
            compareColor = properties.compareStyle.upColor;
        }
        if (data.compare.delta < 0) {
            compareColor = properties.compareStyle.downColor;
        }
    }

    const compareStyle = {
        width: '100%',
        fontSize: 15,
        fontWeight: 700,
        ...properties.compareStyle,
        color: compareColor
    };

    if (!component.dimensions?.length && !component.metrics?.length) {
        return (
            <Box
                height="100%"
                style={properties.cardStyle}
                data-testid={`single-value-${id}`}
            >
                <Flex justifyContent="center" alignItems="center" w="100%">
                    <Text color="inherit" fontSize={16}>
                        Select at least one metric to display single value.
                    </Text>
                </Flex>
            </Box>
        );
    }

    return (
        <Box
            height="100%"
            style={properties.cardStyle}
            data-testid={`single-value-${id}`}
        >
            {data && (
                <>
                    <GenericTooltip label={data.tooltip ?? properties.tooltip}>
                        <Text style={labelStyle}>{data.label}</Text>
                    </GenericTooltip>
                    <Text style={valueStyle}>
                        {formatGenericValue(data.value, data.type, fieldConfig)}
                    </Text>
                    {data?.compare && (
                        <Text style={compareStyle}>
                            {formatGenericValue(
                                data.compare.delta,
                                compareFieldConfig?.type,
                                compareFieldConfig
                            )}
                        </Text>
                    )}
                </>
            )}
        </Box>
    );
};

export default SingleValue;
