import { Box, Flex, Text } from "@chakra-ui/react";
import { useGradientPalletColor } from "./utils";

interface Props {
    data: any;
    properties: any;
    legendProps: any;
}


export const renderLegend: React.FC<Props> = ({data, properties, legendProps}) => {
    const options = data?.yAxisKeys?.map((key: string, index: number) => {
        const { stroke } = useGradientPalletColor(properties, index, 'id');
        return ({
        value: key,
        label: data?.fieldConfigs?.[key]?.title,
        color: stroke
    })});

    return (
        <Flex gap="1rem" mb='2rem'>
            {options.map((option: any) => (
                <Flex
                    gap="0.25rem"
                    alignItems="center"
                    style={legendProps?.listStyle}
                >
                    <Box
                        w="8px"
                        h="8px"
                        borderRadius="50%"
                        backgroundColor={option.color}
                        style={legendProps?.symbolStyle}
                    />
                    <Text fontSize="11px" style={legendProps?.textStyle}>
                        {option.label}
                    </Text>
                </Flex>
            ))}
        </Flex>
    );
};