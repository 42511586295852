import { Component } from 'common/types';
import { selectActiveReportPage } from 'src/redux/features/blueprint/bluePrintSlice';
import { useAppSelector } from './redux';

import { generateRandomId } from 'common/utils';
import { reportPageValidator } from 'common/validators';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import { z } from 'zod';
import { useSetComponentConfig } from './useSetComponentConfig';
import { useCallback } from 'react';

export const useDuplicateComponent = () => {
    const setComponentConfig = useSetComponentConfig();
    const activeReportPage = useAppSelector(selectActiveReportPage);

    return useCallback(
        async (component: Component) => {
            assertIsDefined(activeReportPage);

            component = {
                ...component,
                id: generateRandomId()
            };

            try {
                const validated = reportPageValidator.component.parse(component);
                await setComponentConfig(validated);
                return validated;
            } catch (err) {
                if (err instanceof z.ZodError) {
                    console.log(err.issues);
                }
            }
        },
        [activeReportPage, setComponentConfig]
    );
};
