import {
    startOfMonth,
    endOfMonth,
    startOfQuarter,
    endOfQuarter,
    startOfWeek,
    endOfWeek,
    subMonths,
    subQuarters,
    subWeeks,
    differenceInDays,
    isSameDay,
  } from 'date-fns';
  
 export function getPreviousPeriod(startDate: Date, endDate: Date) {
    const startOfSelectedMonth = startOfMonth(startDate);
    const endOfSelectedMonth = endOfMonth(startDate);
    const startOfSelectedWeek = startOfWeek(startDate);
    const endOfSelectedWeek = endOfWeek(startDate);
  
    // Check if it is a full month
    if (isSameDay(startDate, startOfSelectedMonth) && isSameDay(endDate, endOfSelectedMonth)) {
      const previousMonthStart = startOfMonth(subMonths(startDate, 1));
      const previousMonthEnd = endOfMonth(previousMonthStart);
      return { startDate: previousMonthStart, endDate: previousMonthEnd };
    }
  
    // Check if it is a week
    if (isSameDay(startDate, startOfSelectedWeek) && isSameDay(endDate, endOfSelectedWeek)) {
      const previousWeekStart = startOfWeek(subWeeks(startDate, 1));
      const previousWeekEnd = endOfWeek(previousWeekStart);
      return { startDate: previousWeekStart, endDate: previousWeekEnd };
    }
    return {startDate, endDate};
  }
  