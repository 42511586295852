import { Flex } from '@chakra-ui/react';
import React from 'react';
import { DateRange, DefinedRange, Range, RangeFocus, StaticRange } from 'react-date-range';
import { ActivePeriod, ComparePeriod, IDateRanges } from './DatePicker2';
import { addYears, format, isFuture, isToday } from 'date-fns';
import { sk } from 'date-fns/locale';
import DateRangeInputs from './DateRangeInputs';
import { getPreviousPeriod } from './getPreviousPeriod';

interface CustomDateRangePickerProps {
    properties?: any;
    value: IDateRanges;
    staticRanges?: StaticRange[];
    rangeColors?: string[];
    onChange: (range: IDateRanges) => void;
    minDate?: Date;
    maxDate?: Date;
}

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
    properties,
    value,
    staticRanges,
    rangeColors,
    onChange,
    minDate,
    maxDate
}) => {
    const [comparePeriod, setComparePeriod] = React.useState<ComparePeriod>(
        ComparePeriod.EXACT
    );

    const [focusedRange, setFocusedRange] = React.useState<RangeFocus>([0, 0]);

    const activePeriod =
        focusedRange[0] === 0 ? ActivePeriod.DEFAULT : ActivePeriod.COMPARE;

    const handleChange = (range: IDateRanges) => {
        if (comparePeriod === ComparePeriod.PREVIOUS_PERIOD) {
            const previousPeriod = getPreviousPeriod(
                range.default?.startDate!,
                range.default?.endDate!
            );
            onChange({
                default: range.default,
                compare: {
                    key: ActivePeriod.COMPARE,
                    startDate: previousPeriod.startDate,
                    endDate: previousPeriod.endDate
                }
            });
        } else if (comparePeriod === ComparePeriod.PREVIOUS_YEAR) {
            onChange({
                default: range.default,
                compare: {
                    key: ActivePeriod.COMPARE,
                    startDate: addYears(range.default?.startDate!, -1),
                    endDate: addYears(range.default?.endDate!, -1)
                }
            });
        } else {
            onChange(range);
        }
    };

    const handleChageComparePeriod = (period: ComparePeriod) => {
        setComparePeriod(period);
        if (period === ComparePeriod.PREVIOUS_PERIOD) {
            const previousPeriod = getPreviousPeriod(
                value.default?.startDate!,
                value.default?.endDate!
            );
            onChange({
                default: value.default,
                compare: {
                    key: ActivePeriod.COMPARE,
                    startDate: previousPeriod?.startDate!,
                    endDate: previousPeriod?.endDate!
                }
            });
        } else if (period === ComparePeriod.PREVIOUS_YEAR) {
            onChange({
                default: value.default,
                compare: {
                    key: ActivePeriod.COMPARE,
                    startDate: addYears(value.default?.startDate!, -1),
                    endDate: addYears(value.default?.endDate!, -1)
                }
            });
        }
    };

    return (
        <Flex>
            {staticRanges && staticRanges?.length > 0 && <DefinedRange
                staticRanges={staticRanges}
                ranges={[value.default, value?.compare].filter(Boolean) as Range[]}
                onChange={(item) => {
                    let newValue = { ...value };
                    newValue[activePeriod] = {
                        key: value[activePeriod]?.key ?? ActivePeriod.COMPARE,
                        startDate: item.default.startDate as Date,
                        endDate: item.default.endDate as Date
                    };
                    handleChange(newValue);
                }}
                inputRanges={[]}
                renderStaticRangeLabel={(range) => (
                    <span style={properties?.staticRangeLabelStyle}>{range?.label}</span>
                )}
            />}
            <Flex flexDir="column">
                <DateRangeInputs
                    value={value}
                    onChange={(item) => handleChange({ ...value, ...item })}
                    comparePeriod={comparePeriod}
                    setComparePeriod={handleChageComparePeriod}
                    focusedRange={focusedRange}
                    setFocusedRange={setFocusedRange}
                    rangeColors={rangeColors}
                />
                <DateRange
                    locale={sk}
                    rangeColors={rangeColors}
                    focusedRange={focusedRange}
                    onRangeFocusChange={(focusedRange) => setFocusedRange(focusedRange)}
                    ranges={[value.default, value.compare].filter(Boolean) as Range[]}
                    onChange={(item) =>
                        handleChange({
                            ...value,
                            ...item
                        })
                    }
                    showDateDisplay={false}
                    moveRangeOnFirstSelection={false}
                    dayContentRenderer={(day) => (
                        <span
                            data-testid={`date-picker-day-${format(day, 'd.M.yyyy')}`}
                            style={
                                isFuture(day) || isToday(day)
                                    ? properties?.omitPickerStyle
                                    : properties?.pickerStyle
                            }
                        >
                            {format(day, 'd')}
                        </span>
                    )}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </Flex>
        </Flex>
    );
};

export default CustomDateRangePicker;
