import { useAppSelector } from '@hooks';
import { useEventEmitter } from 'ahooks';
import { defaultTheme } from 'common/constants';
import { ComponentType } from 'common/enums';
import { Component } from 'common/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useCreateComponent } from 'src/hooks/useCreateComponent';
import { useDeleteComponent } from 'src/hooks/useDeleteComponent';
import { useDuplicateComponent } from 'src/hooks/useDuplicateComponent';
import {
    useSetBulkComponentConfig,
    useSetComponentConfig
} from 'src/hooks/useSetComponentConfig';
import { selectActiveReportPage } from 'src/redux/features/blueprint/bluePrintSlice';
import { deepMerge } from 'src/utils/theme';
import { ICanvasContext } from './types';
import { useCanvasAlignment } from './useCanvasAlignment';
import { useCanvasHotkeys } from './useCanvasHotkeys';

const GRID_SIZE = 10;
const OFFSET = 40;

export const CanvasContext = React.createContext<ICanvasContext | undefined>(undefined);

// Custom hook to use CanvasContext
export const useCanvas = () => {
    const context = React.useContext(CanvasContext);
    if (!context) throw new Error('useCanvas must be used within a CanvasProvider');
    return context;
};

interface CanvasProviderProps {
    children: React.ReactNode;
    useSharedReportAPI?: boolean;
    useEditorReport?: boolean;
    reportBasePathName: string;
    containerStyle?: React.CSSProperties;
    height?: number;
    width?: number;
}

// CanvasProvider component
const CanvasProvider: React.FC<CanvasProviderProps> = ({
    children,
    useEditorReport = false,
    useSharedReportAPI = false,
    reportBasePathName,
    containerStyle,
    height,
    width
}) => {
    const editorRef = useRef<HTMLDivElement>(null);
    const eventEmitter = useEventEmitter<string>();
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const canvasObjects = Object.values(activeReportPage?.components || {});

    const [scale, setScale] = useState<number>(1);
    const [canvasHeight, setHeight] = useState<number>(height ?? 0);
    const [canvasWidth, setWidth] = useState<number>(width ?? 0);

    const [showGrid, setShowGrid] = useState<boolean>(false);
    const [canPaste, setCanPaste] = React.useState(false);

    const [activeSelection, setActiveSelection] = useState<Set<string>>(new Set());
    const [activeSelectedComponentId, setActiveSelectedComponentId] =
        useState<string>('');
    const [enabledInlineEditor, setEnabledInlineEditor] = useState<boolean>(false);

    const createComponent = useCreateComponent();
    const deleteComponent = useDeleteComponent();
    const duplicateObject = useDuplicateComponent();
    const setComponentConfig = useSetComponentConfig();
    const setBulkComponentConfig = useSetBulkComponentConfig();

    const containerRef = useRef<HTMLDivElement>(null);
    const isSelectAll = useRef<boolean>(false);

    // Custom handlers
    const addComponent = useCallback(
        async (type: ComponentType, x?: number, y?: number) => {
            const component = await createComponent(type, x, y);

            if (component) {
                setActiveSelection(new Set([component.id]));
                setActiveSelectedComponentId(component.id);
            }
        },
        [createComponent]
    );

    const deleteObject = useCallback(
        async (id: string) => {
            setActiveSelection(new Set());
            setActiveSelectedComponentId('');
            await deleteComponent(id);
        },
        [deleteComponent]
    );

    // Handle duplication
    const handleDuplicateObject = useCallback(
        async (component: Component) => {
            let updatedComponent: Component = component;

            const { x, y, w, h, type } = component;
            let newX = x + OFFSET;
            let newY = y + OFFSET;

            if (canvasObjects.length > 1) {
                // Filter only objects of the same type
                const sameTypeObjects = canvasObjects.filter(
                    (obj) => obj.type === type && obj.w === w && obj.h === h
                );

                // Find objexts in the same row (close to the same Y coordinate)
                const sameRowObjects = sameTypeObjects.filter((obj) => obj.y === y);

                // Find objects in the same column (close to the same X coordinate)
                const sameColumnObjects = sameTypeObjects.filter((obj) => obj.x === x);

                const objectsAlignedHorizontally =
                    sameRowObjects.length > 1 && sameColumnObjects.length === 1;
                const objectsAlignedVertically =
                    sameColumnObjects.length > 1 && sameRowObjects.length === 1;

                // Compute the next X position in the row (to the right of the farthest object)
                const maxXInRow =
                    sameRowObjects.length > 0
                        ? Math.max(...sameRowObjects.map((obj) => obj.x + obj.w))
                        : x + w;
                newX = maxXInRow + OFFSET;

                // Compute the next Y position in the column (below the farthest object)
                const maxYInColumn =
                    sameColumnObjects.length > 0
                        ? Math.max(...sameColumnObjects.map((obj) => obj.y + obj.h))
                        : y + h;
                newY = maxYInColumn + OFFSET;

                if (objectsAlignedHorizontally) {
                    newY = y;
                    if (newX + w + OFFSET >= canvasWidth) {
                        newX = canvasWidth - w - OFFSET;
                    }
                }
                if (objectsAlignedVertically) {
                    newX = x;
                    if (newY + h + OFFSET >= canvasHeight) {
                        newY = canvasHeight - h - OFFSET;
                    }
                }
            }

            // Snap to the nearest grid
            newY = Math.round(newY / GRID_SIZE) * GRID_SIZE;
            newX = Math.round(newX / GRID_SIZE) * GRID_SIZE;

            // Ensure the snapped position is within bounds
            newY = Math.min(newY, canvasHeight - component.h);
            newX = Math.min(newX, canvasWidth - component.w);

            if (newX === x && newY === y) {
                newX =
                    x + OFFSET + w >= canvasWidth ? canvasWidth - OFFSET - w : x + OFFSET;
                newY =
                    y + OFFSET + h >= canvasHeight
                        ? canvasHeight - OFFSET - h
                        : y + OFFSET;
            }

            updatedComponent = {
                ...updatedComponent,
                x: newX,
                y: newY
            };

            // Duplicate the updated component
            await duplicateObject(updatedComponent);
        },
        [duplicateObject]
    );

    const duplicateSelectedObjects = React.useCallback(() => {
        activeSelection.forEach(async (id) => {
            const component = canvasObjects.find((obj) => obj.id === id);
            if (!component) return;
            await handleDuplicateObject(component);
        });
    }, [canvasObjects, activeSelection, duplicateObject]);

    const deleteSelectedObjects = React.useCallback(() => {
        activeSelection.forEach(async (id) => {
            const component = canvasObjects.find((obj) => obj.id === id);
            if (component?.type !== ComponentType.DATE_RANGE) {
                await deleteObject(id);
            }
        });
    }, [activeSelection, deleteObject]);

    const handleSetActiveSelection = useCallback((ids: Set<string>) => {
        setActiveSelection(ids);
        setActiveSelectedComponentId(
            ids.size === 1 ? ids.values().next().value ?? '' : ''
        );
    }, []);

    const handleCopyStyles = React.useCallback(
        async (component: Component) => {
            if (activeSelection.size !== 1) return;
            if (!component) return;
            await navigator.clipboard.writeText(
                JSON.stringify({
                    type: component.type,
                    properties: defaultTheme.copyProperties(
                        component.type,
                        component.properties
                    )
                })
            );
        },
        [activeSelection]
    );

    const handlePasteStyles = React.useCallback(
        async (component: Component) => {
            if (activeSelection.size !== 1) return;
            if (!component) return;
            try {
                const clipboardComponent = await navigator.clipboard.readText();
                const parsedClipboardComponent = JSON.parse(clipboardComponent);
                if (!parsedClipboardComponent) return;
                if (parsedClipboardComponent.type !== component.type) {
                    toast.error('Cannot paste styles from different components');
                    return;
                }
                const componentWithNewStyles = {
                    ...component,
                    properties: deepMerge(
                        component.properties,
                        parsedClipboardComponent?.properties
                    )
                };
                setComponentConfig(componentWithNewStyles);
            } catch (error) {
                toast.error('Error pasting styles');
            }
        },
        [activeSelection, canvasObjects, setComponentConfig]
    );

    const canPasteStyles = useCallback(
        (component: Component): boolean => {
            if (activeSelection.size !== 1) return false;
            if (!component) return false;

            navigator.clipboard
                .readText()
                .then((clipboardComponent) => {
                    try {
                        const parsedClipboardComponent = JSON.parse(clipboardComponent);
                        if (
                            parsedClipboardComponent &&
                            parsedClipboardComponent.type === component.type
                        ) {
                            setCanPaste(true);
                        } else {
                            setCanPaste(false);
                        }
                    } catch (error) {
                        setCanPaste(false);
                    }
                })
                .catch(() => {
                    setCanPaste(false);
                });

            return canPaste;
        },
        [activeSelection, canPaste]
    );

    // Update components
    const updateComponents = useCallback(
        async (updatedComponents: Component[]) => {
            await setBulkComponentConfig(updatedComponents);
        },
        [setComponentConfig]
    );

    // Hotkeys registration
    useCanvasHotkeys({
        enabledInlineEditor,
        canvasObjects,
        activeSelection,
        setActiveSelection: handleSetActiveSelection,
        duplicateSelectedObjects,
        deleteSelectedObjects,
        updateComponents
    });

    // Alignment actions
    const alignActions = useCanvasAlignment({
        canvasObjects,
        activeSelection,
        updateComponents
    });

    // image reset aspect ratio, from url get image size
    const resetAspectRatio = useCallback(
        async (id: string) => {
            const component = canvasObjects.find((obj) => obj.id === id);
            if (!component) return;
            if ([ComponentType.IMAGE, ComponentType.LOGO].includes(component.type)) {
                const img = new Image();
                img.src = component.properties.src;
                img.onload = () => {
                    const { width, height } = img;
                    const updatedComponent = {
                        ...component,
                        w: width,
                        h: height
                    };
                    setComponentConfig(updatedComponent);
                };
            }
        },
        [canvasObjects, setComponentConfig]
    );

    // Context value
    const contextValue = {
        state: {
            canvasObjects,
            activeSelection,
            enabledInlineEditor,
            activeSelectedComponentId,
            isReadOnly,
            useSharedReportAPI: useSharedReportAPI,
            useEditorReport: useEditorReport,
            reportBasePathName: reportBasePathName,
            scale,
            height: canvasHeight,
            width: canvasWidth,
            showGrid,
            canPasteStyles: canPaste
        },
        actions: {
            setActiveSelection: handleSetActiveSelection,
            addComponent,
            deleteObject,
            setEnabledInlineEditor,
            duplicateObject: handleDuplicateObject,
            setIsReadOnly,
            copyStyles: handleCopyStyles,
            pasteStyles: handlePasteStyles,
            align: alignActions,
            setScale: setScale,
            resetAspectRatio,
            setHeight,
            setWidth,
            setShowGrid
        },
        eventEmitter,
        editorRef
    };

    // Handle outside click
    useEffect(() => {
        const handleMouseDown = () => {
            if (!isSelectAll.current) return;
            if (activeSelection.size > 0) {
                isSelectAll.current = false;
                setActiveSelection(new Set());
            }
        };

        document.addEventListener('mousedown', handleMouseDown);
        return () => document.removeEventListener('mousedown', handleMouseDown);
    }, []);

    // Update canPaste state when activeSelection changes
    useEffect(() => {
        if (activeSelection.size === 1) {
            const component = canvasObjects.find(
                (obj) => obj.id === Array.from(activeSelection)[0]
            );
            if (component) {
                canPasteStyles(component);
            }
        } else {
            setCanPaste(false);
        }
    }, [activeSelection, canvasObjects, canPasteStyles]);

    return (
        <div ref={containerRef} style={containerStyle}>
            <CanvasContext.Provider value={contextValue}>
                {children}
            </CanvasContext.Provider>
        </div>
    );
};

export default CanvasProvider;
