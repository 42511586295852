import { HorizontalAlignmentType, VerticalAlignmentType } from "recharts/types/component/DefaultLegendContent";

export const useGradientPalletColor = (properties: any, index: number, id: string) => {
    let fill = 'gray';
    let stroke = 'gray';
    if (properties?.component?.linearGradients?.length) {
        const idx =
            index % properties.component.linearGradients.length;
        fill = `url(#${`${idx.toString()}-${id}`})`;
        stroke =
            properties.component.linearGradients[idx]
                .legendSymbolFillColor;
    } else if (properties.component?.pallet?.length) {
        fill =
            properties.component.pallet[
                index % properties.component.pallet.length
            ];
        stroke = fill;
    }
    return {fill, stroke};
}

export const DEFAULT_PADDING_FACTOR = 0.15;
export const DEFAULT_MARGIN = { left: 20, right: 20, top: 20, bottom: 20 };
export const DEFAULT_LEGEND_PROPERTIES = {
    align: 'left',
    verticalAlign: 'top'
};

interface LegendProperties {
    align: HorizontalAlignmentType;
    verticalAlign: VerticalAlignmentType;
    wrapperStyle: React.CSSProperties;
}

export const useLegendProperties = (legendProps: any): LegendProperties => {
    return {
        align: legendProps?.align ?? DEFAULT_LEGEND_PROPERTIES.align,
        verticalAlign: legendProps?.verticalAlign ?? DEFAULT_LEGEND_PROPERTIES.verticalAlign,
        wrapperStyle: legendProps?.wrapperStyle ?? {
            paddingTop: legendProps?.verticalAlign === 'bottom' ? '2rem' : '0',
            paddingBottom: legendProps?.verticalAlign === 'top' ? '2rem' : '0'
        }
    };
}