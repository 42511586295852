import { cardStyle, cartesianGrid, pallet, tooltipStyle, tooltipTextStyle } from "./common.js";
export const defaultTinyBarChart = {
    properties: {
        style: {},
        component: {
            formatType: 'PERCENTAGE',
            cartesianGrid: cartesianGrid,
            pallet: pallet,
            tooltip: {
                style: tooltipStyle,
                textStyle: tooltipTextStyle
            }
        },
        cardStyle: cardStyle
    }
};
export const copyTinyBarChartStyles = (properties) => {
    const _properties = { ...properties };
    return _properties;
};
