import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Switch,
    Text
} from '@chakra-ui/react';

import { createStaticRanges, StaticRange } from 'react-date-range';

import { CalendarIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { styleValidator } from 'common/validators';
import { addDays, endOfYesterday } from 'date-fns';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { z } from 'zod';
import CustomDateRangePicker from './CustomDateRangePicker';
import { staticRanges } from './staticRanges';
import { getEnv } from 'src/utils';

interface IDateRange {
    startDate: Date;
    endDate: Date;
    key: string;
}

export enum ActiveDateType {
    START = 'start',
    END = 'end'
}

export enum ActivePeriod {
    DEFAULT = 'default',
    COMPARE = 'compare'
}

export enum ComparePeriod {
    PREVIOUS_PERIOD = 'previous_period',
    PREVIOUS_YEAR = 'previous_year',
    EXACT = 'exact'
}

export interface IDateRanges {
    [ActivePeriod.DEFAULT]: IDateRange;
    [ActivePeriod.COMPARE]?: IDateRange;
}

interface Props extends z.infer<typeof styleValidator.dateRange> {
    value: IDateRanges;
    onChange: (internalValue: IDateRanges) => void;
    minDate?: EpochTimeStamp;
    maxDate?: EpochTimeStamp;
}

const env = getEnv();

const isProductionEnv = ['mds', 'production'].includes(env);

export default function DatePicker2({
    onChange,
    value,
    properties,
    minDate,
    maxDate
}: Props) {
    let minimumDate = new Date();
    let maximumDate = new Date();
    if (minDate && maxDate) {
        minimumDate = new Date(minDate);
        maximumDate = new Date(maxDate);
        if (maximumDate < minimumDate) {
            minimumDate = addDays(new Date(), -7);
            maximumDate = endOfYesterday();
        }
    }
    if (!Boolean(minDate) && Boolean(maxDate)) {
        maximumDate = endOfYesterday();
        minimumDate = new Date(0);
    }
    if (Boolean(minDate) && !Boolean(maxDate)) {
        minimumDate = new Date(minDate ?? 0);
        maximumDate = endOfYesterday();
    }

    const [internalValue, setInternalValue] = useState<IDateRanges>({
        default: {
            startDate: value?.default.startDate ?? addDays(new Date(), -7),
            endDate: value?.default.endDate ?? endOfYesterday(),
            key: 'default'
        },
        ...(value?.compare
            ? {
                  compare: {
                      startDate: value?.compare.startDate ?? addDays(new Date(), -14),
                      endDate: value?.compare.endDate ?? addDays(new Date(), -7),
                      key: 'compare'
                  }
              }
            : {})
    });

    // compare period switch
    const [isComparePeriod, setIsComparePeriod] = useState<boolean>(
        internalValue?.compare ? true : false
    );

    const handleSetComparePeriod = (comparePeriod: boolean) => {
        setIsComparePeriod(comparePeriod);
        if (comparePeriod) {
            setInternalValue({
                default: {
                    startDate: value?.default.startDate ?? addDays(new Date(), -7),
                    endDate: value?.default.endDate ?? endOfYesterday(),
                    key: 'default'
                },
                compare: {
                    startDate: addDays(value?.compare?.startDate ?? new Date(), -14),
                    endDate: addDays(value?.compare?.endDate ?? new Date(), -7),
                    key: 'compare'
                }
            });
        } else {
            setInternalValue({
                default: {
                    startDate: value?.default.startDate ?? addDays(new Date(), -7),
                    endDate: value?.default.endDate ?? endOfYesterday(),
                    key: 'default'
                }
            });
        }
    };

    const value_ = internalValue;

    const startDateString = DateTime.fromJSDate(value_.default.startDate).toFormat(
        'dd.MM.yyyy'
    );
    const endDateString = DateTime.fromJSDate(value_.default.endDate).toFormat(
        'dd.MM.yyyy'
    );
    const compareStartDateString = value_?.compare
        ? DateTime.fromJSDate(value_.compare.startDate).toFormat('dd.MM.yyyy')
        : undefined;
    const compareEndDateString = value_?.compare
        ? DateTime.fromJSDate(value_.compare.endDate).toFormat('dd.MM.yyyy')
        : undefined;

    const filteredStaticRanges = useMemo(() => {
        const min = addDays(new Date(minimumDate ?? '2023-01-01'), -1);
        const max = maximumDate ? new Date(maximumDate) : endOfYesterday();

        if (!Boolean(minDate) && !Boolean(maxDate)) {
            return staticRanges;
        }
        let ranges = staticRanges.filter((r: any) => {
            const range = r.range();
            const rangeStart = range.startDate;
            const rangeEnd = range.endDate;

            return (
                rangeStart.getTime() >= min.getTime() &&
                rangeStart.getTime() <= max.getTime() &&
                rangeEnd.getTime() <= max.getTime()
            );
        });
        if (ranges.length < 5) {
            // if there are less than 5 ranges, add quartal ranges Q1, Q2, Q3, Q4 to today's date
            const currentYear = new Date().getFullYear();
            const previousYear = currentYear - 1;
            const quarterRanges = createStaticRanges([
                // add last year's Q1, Q2, Q3, Q4
                {
                    label: `Q1 ${previousYear.toString()}`,
                    range: () => ({
                        startDate: new Date(previousYear, 0, 1),
                        endDate: new Date(previousYear, 2, 31)
                    }),
                    hasCustomRendering: true
                },
                {
                    label: `Q2 ${previousYear.toString()}`,
                    range: () => ({
                        startDate: new Date(previousYear, 3, 1),
                        endDate: new Date(previousYear, 5, 30)
                    }),
                    hasCustomRendering: true
                },
                {
                    label: `Q3 ${previousYear.toString()}`,
                    range: () => ({
                        startDate: new Date(previousYear, 6, 1),
                        endDate: new Date(previousYear, 8, 30)
                    }),
                    hasCustomRendering: true
                },
                {
                    label: `Q4 ${previousYear.toString()}`,
                    range: () => ({
                        startDate: new Date(previousYear, 9, 1),
                        endDate: new Date(previousYear, 11, 31)
                    }),
                    hasCustomRendering: true
                },
                // add this year's Q1, Q2, Q3, Q4
                {
                    label: `Q1 ${currentYear.toString()}`,
                    range: () => ({
                        startDate: new Date(currentYear, 0, 1),
                        endDate: new Date(currentYear, 2, 31)
                    }),
                    hasCustomRendering: true
                },
                {
                    label: `Q2 ${currentYear.toString()}`,
                    range: () => ({
                        startDate: new Date(currentYear, 3, 1),
                        endDate: new Date(currentYear, 5, 30)
                    }),
                    hasCustomRendering: true
                },
                {
                    label: `Q3 ${currentYear.toString()}`,
                    range: () => ({
                        startDate: new Date(currentYear, 6, 1),
                        endDate: new Date(currentYear, 8, 30)
                    }),
                    hasCustomRendering: true
                },
                {
                    label: `Q4 ${currentYear.toString()}`,
                    range: () => ({
                        startDate: new Date(currentYear, 9, 1),
                        endDate: new Date(currentYear, 11, 31)
                    }),
                    hasCustomRendering: true
                }
            ]).filter((r: any) => {
                const range = r.range();
                const rangeStart = range.startDate;
                const rangeEnd = range.endDate;
                return (
                    rangeStart.getTime() >= min.getTime() &&
                    rangeEnd.getTime() <= max.getTime()
                );
            });
            ranges = ranges.concat(quarterRanges);
        }

        return ranges;
    }, [minimumDate, maximumDate]);

    return (
        <Popover placement="bottom-end">
            {({ onClose }) => (
                <>
                    <PopoverTrigger>
                        <Button
                            leftIcon={
                                properties?.showCalendarIcon ? (
                                    <CalendarIcon style={properties?.iconStyle} />
                                ) : undefined
                            }
                            rightIcon={
                                properties?.showDropdownIndicator ? (
                                    <ChevronDownIcon h={6} w={6} />
                                ) : undefined
                            }
                            height="100%"
                            variant="datepicker"
                            data-testid="date-picker-button"
                            sx={properties?.buttonStyle}
                        >
                            <Flex flexDir="column" alignItems="start">
                                {properties?.text
                                    ? properties.text
                                    : `${startDateString} - ${endDateString}`}
                                {!properties?.text &&
                                    compareStartDateString &&
                                    compareEndDateString && (
                                        <Text fontSize="xs" fontWeight={300} mt="5px">
                                            {`vs. ${compareStartDateString} - ${compareEndDateString}`}
                                        </Text>
                                    )}
                            </Flex>
                        </Button>
                    </PopoverTrigger>
                    <Portal>
                        <Box zIndex={99999} position="relative">
                            <PopoverContent
                                w="auto"
                                data-testid="date-picker-popover"
                                position="relative"
                            >
                                <Box>
                                    <CustomDateRangePicker
                                        properties={properties}
                                        staticRanges={
                                            filteredStaticRanges as StaticRange[]
                                        }
                                        onChange={(item) => setInternalValue(item)}
                                        value={internalValue}
                                        rangeColors={properties?.rangeColors}
                                        minDate={
                                            minDate
                                                ? new Date(minimumDate ?? '2023-01-01')
                                                : undefined
                                        }
                                        maxDate={
                                            maxDate
                                                ? maximumDate
                                                    ? new Date(maximumDate)
                                                    : endOfYesterday()
                                                : undefined
                                        }
                                    />
                                </Box>
                                <Box bottom={'1rem'} right="1.5rem" px="1.5rem" pb="1rem">
                                    <Flex
                                        gap="0.5rem"
                                        justifyContent={
                                            !isProductionEnv
                                                ? 'space-between'
                                                : 'flex-end'
                                        }
                                    >
                                        {!isProductionEnv && (
                                            <FormControl
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <FormLabel
                                                    htmlFor="compare-periods"
                                                    mb="0"
                                                >
                                                    Compare periods?
                                                </FormLabel>
                                                <Switch
                                                    id="compare-periods"
                                                    onChange={() =>
                                                        handleSetComparePeriod(
                                                            !isComparePeriod
                                                        )
                                                    }
                                                    isChecked={isComparePeriod}
                                                />
                                            </FormControl>
                                        )}
                                        <Button
                                            variant="darkBrand"
                                            {...properties?.submitButtonStyle}
                                            _hover={properties?.submitButtonStyle?._hover}
                                            onClick={() => {
                                                onChange(internalValue);
                                                onClose();
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </Flex>
                                </Box>
                            </PopoverContent>
                        </Box>
                    </Portal>
                </>
            )}
        </Popover>
    );
}
