import { Box } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppSelector } from '@hooks';
import { defaultTheme } from 'common/constants';
import { styleValidator } from 'common/validators';
import { FormProvider, useForm } from 'react-hook-form';
import AutoSave from 'src/blueprint/pages/editor/components/AutoSave';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useSetComponentConfig } from 'src/hooks/useSetComponentConfig';
import { selectComponentById } from 'src/redux/features/blueprint/bluePrintSlice';
import { z } from 'zod';
import { FormAccordionItem } from '../../Accordion';
import CardForm from '../../forms/CardForm';
import CartesianGridForm from '../../forms/CartesianGridForm';
import GradientForm from '../../forms/GradientForm';
import PalletForm from '../../forms/PalletForm';
import PositionForm from '../../forms/PositionForm';
import StyleDivider from '../../StyleDivider';
import TextForm from '../../forms/TextForm';

type FormData = z.infer<typeof styleValidator.tinyBarChart>;

const TinyBarChartStyle: React.FC = () => {
    const {
        state: { activeSelectedComponentId }
    } = useCanvas();
    const componentToEdit = useAppSelector(
        selectComponentById(activeSelectedComponentId)
    );
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultTheme.components.TINY_BAR_CHART,
        values: componentToEdit as any,
        resolver: zodResolver(styleValidator.tinyBarChart),
        mode: 'onBlur'
    });

    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    };

    return (
        <Box>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <PositionForm />
                    <StyleDivider />
                    <FormAccordionItem title="Component styles">
                        <GradientForm<FormData>
                            name={'properties.component.linearGradients'}
                            title="Gradients"
                        />
                        <PalletForm<FormData>
                            name={'properties.component.pallet'}
                            title="Pallet"
                        />
                        <CartesianGridForm<FormData>
                            objPath={'properties.component.cartesianGrid'}
                        />
                        <CardForm<FormData>
                            objPath={'properties.component.tooltip.style'}
                            title='Tooltip style'
                        />
                        <TextForm<FormData>
                            objPath={'properties.component.tooltip.textStyle'}
                        />
                    </FormAccordionItem>
                    <StyleDivider />
                    <CardForm<FormData> objPath={'properties.cardStyle'} />
                    <input hidden type="submit" />
                    <AutoSave
                        onSubmit={onSubmit}
                        defaultValues={defaultTheme.components.TINY_BAR_CHART}
                    />
                </form>
            </FormProvider>
        </Box>
    );
};

export default TinyBarChartStyle;
