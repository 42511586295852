import { Box, Flex } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppSelector } from '@hooks';
import { defaultTheme } from 'common/constants';
import { styleValidator } from 'common/validators';
import { FormProvider, useForm } from 'react-hook-form';
import AutoSave from 'src/blueprint/pages/editor/components/AutoSave';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useSetComponentConfig } from 'src/hooks/useSetComponentConfig';
import { selectComponentById } from 'src/redux/features/blueprint/bluePrintSlice';
import { z } from 'zod';
import CardForm from '../../forms/CardForm';
import CartesianGridForm from '../../forms/CartesianGridForm';
import PalletForm from '../../forms/PalletForm';
import PositionForm from '../../forms/PositionForm';
import StyleDivider from '../../StyleDivider';
import { FormAccordion, FormAccordionItem } from '../../Accordion';
import TextForm from '../../forms/TextForm';
import ToggleForm from '../../forms/ToggleForm';

type FormData = z.infer<typeof styleValidator.lineChart>;

const LineChartStyle: React.FC = () => {
    const {
        state: { activeSelectedComponentId }
    } = useCanvas();
    const componentToEdit = useAppSelector(
        selectComponentById(activeSelectedComponentId)
    );
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultTheme.components.LINE_CHART,
        values: componentToEdit as any,
        resolver: zodResolver(styleValidator.lineChart),
        mode: 'onBlur'
    });
    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    };

    return (
        <Box>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <PositionForm />
                    <StyleDivider />
                    <FormAccordion>
                        <FormAccordionItem title="Component styles">
                            <ToggleForm<FormData> name={'properties.component.twoYAxes'} title="Show two Y Axes" />
                            <ToggleForm<FormData> name={'properties.component.metricSelection'} title="Show metric selection" />
                            <PalletForm<FormData>
                                name={'properties.component.pallet'}
                                title="Pallet"
                            />
                            <CartesianGridForm<FormData>
                                objPath={'properties.component.cartesianGrid'}
                            />
                            <CardForm<FormData>
                                objPath={'properties.component.tooltip.style'}
                                title="Tooltip style"
                            />
                            <TextForm<FormData>
                                objPath={'properties.component.tooltip.textStyle'}
                            />
                        </FormAccordionItem>
                    </FormAccordion>
                    <StyleDivider />
                    <CardForm<FormData> objPath={'properties.cardStyle'} />
                    <input hidden type="submit" />
                    <AutoSave
                        onSubmit={onSubmit}
                        defaultValues={defaultTheme.components.LINE_CHART}
                    />
                </form>
            </FormProvider>
        </Box>
    );
};

export default LineChartStyle;
