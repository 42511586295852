import { CalendarIcon } from '@chakra-ui/icons';
import {
    Collapse,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Input,
    InputGroup,
    InputRightElement
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { RangeFocus } from 'react-date-range';
import { AiOutlineSwapRight } from 'react-icons/ai';
import { ComparePeriod, IDateRanges } from './DatePicker2';

interface DateRangeInputsProps {
    value: IDateRanges;
    comparePeriod: ComparePeriod;
    setComparePeriod: (comparePeriod: ComparePeriod) => void;
    onChange: (range: IDateRanges) => void;
    focusedRange: RangeFocus;
    setFocusedRange: (range: RangeFocus) => void;
    rangeColors?: string[];
}

const DateRangeInputs: React.FC<DateRangeInputsProps> = ({
    value,
    comparePeriod,
    focusedRange,
    setFocusedRange,
    rangeColors
}) => {
    const OPTIONS = [
        {
            label: 'Select period',
            value: ComparePeriod.EXACT
        },
        {
            label: 'Previous period',
            value: ComparePeriod.PREVIOUS_PERIOD
        },
        {
            label: 'Previous year',
            value: ComparePeriod.PREVIOUS_YEAR
        }
    ];
    const comparePeriodLabel = value.compare
        ? `${format(value.compare.startDate, 'dd-MM-yyyy')} - ${format(value.compare.endDate, 'dd-MM-yyyy')}`
        : '';

    let compareValue = OPTIONS.find((option) => option.value === comparePeriod);
    if (compareValue && comparePeriod) {
        compareValue = {
            value: compareValue?.value,
            label: `${compareValue?.label} ${comparePeriodLabel}`
        };
    }

    return (
        <Flex flexDir="column" gap="1rem" p="1rem" w="100%">
            <Flex gap="1rem" alignItems="center" w="100%">
                <FormControl>
                    <FormLabel fontSize="xs">Start date</FormLabel>
                    <Flex alignItems="center" gap="1rem">
                        <InputGroup>
                            <Input
                                variant="editor"
                                w="100%"
                                value={format(value.default.startDate, 'dd.MM.yyyy')}
                                onFocus={() => setFocusedRange([0, 0])}
                                readOnly
                                style={
                                    focusedRange[0] === 0 && focusedRange[1] === 0
                                        ? {
                                              border: `1px solid`,
                                              borderColor: rangeColors?.[0] ?? 'blue'
                                          }
                                        : {
                                              border: '1px solid gray'
                                          }
                                }
                            />
                            <InputRightElement>
                                <CalendarIcon />
                            </InputRightElement>
                        </InputGroup>
                        <Icon as={AiOutlineSwapRight} />
                    </Flex>
                </FormControl>

                <FormControl>
                    <FormLabel fontSize="xs">End date</FormLabel>
                    <InputGroup>
                        <Input
                            variant="editor"
                            w="100%"
                            value={format(value.default.endDate, 'dd.MM.yyyy')}
                            onFocus={() => setFocusedRange([0, 1])}
                            readOnly
                            style={
                                focusedRange[0] === 0 && focusedRange[1] === 1
                                    ? {
                                          border: `1px solid`,
                                          borderColor: rangeColors?.[0] ?? 'blue'
                                      }
                                    : {
                                          border: '1px solid gray'
                                      }
                            }
                        />
                        <InputRightElement>
                            <CalendarIcon />
                        </InputRightElement>
                    </InputGroup>
                </FormControl>
            </Flex>
            <Collapse in={Boolean(value.compare)}>
                <Flex gap="1rem" alignItems="center" w="100%">
                    <FormControl>
                        <FormLabel fontSize="xs">Start date</FormLabel>
                        <Flex alignItems="center" gap="1rem">
                            <InputGroup>
                                <Input
                                    variant="editor"
                                    w="100%"
                                    value={
                                        value.compare?.startDate
                                            ? format(
                                                  value.compare?.startDate,
                                                  'dd.MM.yyyy'
                                              )
                                            : ''
                                    }
                                    onFocus={() => setFocusedRange([1, 0])}
                                    readOnly
                                    style={
                                        focusedRange[0] === 1 && focusedRange[1] === 0
                                            ? {
                                                  border: `1px solid`,
                                                  borderColor: rangeColors?.[1] ?? 'blue'
                                              }
                                            : {
                                                  border: '1px solid gray'
                                              }
                                    }
                                />
                                <InputRightElement>
                                    <CalendarIcon />
                                </InputRightElement>
                            </InputGroup>
                            <Icon as={AiOutlineSwapRight} />
                        </Flex>
                    </FormControl>
                    <FormControl>
                        <FormLabel fontSize="xs">End date</FormLabel>
                        <InputGroup>
                            <Input
                                variant="editor"
                                w="100%"
                                value={value.compare?.endDate ? format(value.compare?.endDate, 'dd.MM.yyyy'): ''}
                                onFocus={() => setFocusedRange([1, 1])}
                                readOnly
                                style={
                                    focusedRange[0] === 1 && focusedRange[1] === 1
                                        ? {
                                              border: `1px solid`,
                                              borderColor: rangeColors?.[1] ?? 'blue'
                                          }
                                        : {
                                              border: '1px solid gray'
                                          }
                                }
                            />
                            <InputRightElement>
                                <CalendarIcon />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </Flex>
            </Collapse>
            {/* <FormControl>
                <FormLabel fontSize="xs">Period to compare to</FormLabel>
                <StyledSelect
                    variant="editor"
                    options={OPTIONS}
                    value={compareValue}
                    onChange={(item: any) => {
                        setComparePeriod(item.value as ComparePeriod);
                    }}
                    onFocus={() => setFocusedRange([1, 0])}
                    style={
                        focusedRange[0] === 1
                            ? { borderColor: 'red.500' }
                            : { borderColor: 'gray.200' }
                    }
                />
            </FormControl> */}
        </Flex>
    );
};

export default DateRangeInputs;
