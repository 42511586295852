import { cardStyle, pallet, tooltipStyle, tooltipTextStyle } from "./common.js";
const defaultPieChart = {
    properties: {
        style: {},
        component: {
            legend: {
                align: 'right',
                height: 20,
                layout: 'vertical',
                symbol: 'square',
                fontSize: 14,
                itemsAlign: 'left',
                verticalAlign: 'middle'
            },
            pallet: pallet,
            label: 'Donut Chart',
            tooltip: {
                style: tooltipStyle,
                textStyle: tooltipTextStyle
            }
        },
        labelStyle: {
            left: '30%',
            color: 'black',
            display: 'none',
            fontSize: '16px',
            textAlign: 'left',
            fontWeight: 400,
            lineHeight: '20px',
            fontFamily: 'Arial'
        },
        cardStyle: cardStyle
    }
};
export const copyPieChartStyles = (properties) => {
    return {
        style: {
            ...properties.style
        },
        component: {
            legend: {
                ...properties.component.legend
            },
            pallet: properties.component.pallet,
        },
        labelStyle: {
            ...properties.labelStyle
        },
        cardStyle: {
            ...properties.cardStyle
        }
    };
};
export default defaultPieChart;
