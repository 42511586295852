import { Box, Flex, Text } from "@chakra-ui/react"
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form"
import AddIconButton from "../../AddIconButton"
import ColorPickerInput from "../inputs/ColorPickerInput"
import SquareColorPickerInput from "../inputs/SquareColorPickerInput"
import FormPropertyRow from "../layout/FormPropertyRow"
import FormTitle from "../layout/FormTitle"
import { useId } from "react"

const GRADIENT_SAMPLE = {
    "x1": "1",
    "x2": "0",
    "y1": "0",
    "y2": "3",
    "stops": [
        {
            "offset": "0%",
            "stopColor": "rgb(252,187,91)",
            "stopOpacity": 1
        },
        {
            "offset": "50%",
            "stopColor": "rgba(199,66,149,0.475)",
            "stopOpacity": 1
        },
        {
            "offset": "100%",
            "stopColor": "rgba(233,133,195,0)",
            "stopOpacity": 0
        }
    ],
    "legendSymbol": "square",
    "legendSymbolFillColor": "rgb(252,187,91)",
    "legendSymbolTextColor": "white"
}

const gradientCoordinatesToDegrees = (x1: number, x2: number, y1: number, y2: number): number => {
    // Calculate the change in x and y
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;

    // Calculate the angle in radians using Math.atan2
    const angleRadians = Math.atan2(deltaY, deltaX);

    // Convert the angle to degrees
    let angleDegrees = angleRadians * (180 / Math.PI);

    // Ensure the angle is in the range [0, 360]
    if (angleDegrees < 0) {
        angleDegrees += 360;
    }

    return angleDegrees;
}

const degreesToGradientCoordinates = (degrees: number): { x1: string, x2: string, y1: string, y2: string } => {
    const radians = degrees * Math.PI / 180;
    const x1 = Math.cos(radians).toFixed(2);
    const x2 = Math.cos(radians + Math.PI).toFixed(2);
    const y1 = Math.sin(radians).toFixed(2);
    const y2 = Math.sin(radians + Math.PI).toFixed(2);
    return { x1, x2, y1, y2 };
}

const extractGradientValues = (gradient: string): { x1: string, x2: string, y1: string, y2: string, stops: { stopColor: string, offset: string, stopOpacity: number }[] } | undefined => {
    if (gradient.includes('radial-gradient')) {
        return undefined;
    }
    // Regex to capture degrees, rgba, and stops
    const gradientRegex = /(\d+deg),\s*((rgba?|RGBA?)\([\d,.\s]+\))\s*(\d+%)?,\s*((rgba?|RGBA?)\([\d,.\s]+\))\s*(\d+%)?,\s*((rgba?|RGBA?)\([\d,.\s]+\))\s*(\d+%)?/;

    // Apply the regex to the input gradient string
    const match = gradient.toLowerCase().match(gradientRegex);


    if (match) {
        const angle = parseInt(match[1]);
        const stops = match.slice(2).filter((_, i) => i % 3 === 0).map((color, i) => {
            return {
                stopColor: color,
                offset: match.slice(2).filter((_, i) => i % 3 === 2)[i],
                stopOpacity: 1
            }
        })
        return {
            ...degreesToGradientCoordinates(angle),
            stops
        }
    }

    return undefined;
}

const GradientForm = <T extends FieldValues>({ name, title, withLegendSymbolTextColor = false }: { name: Path<T>, title?: string, withLegendSymbolTextColor?: boolean }) => {
    const methods = useFormContext<T>();
    const id = useId();

    return <Flex flexDir='column'>
        <Flex mt={4} alignItems='center' justifyContent={'space-between'}>
            <Text textStyle='_h2'>{title ?? ''}</Text>
            <Controller
                control={methods.control}
                name={name}
                render={({ field }) => (
                    <AddIconButton onClick={() => {
                        field.onChange({ target: { value: [...(field.value ? field.value : []), GRADIENT_SAMPLE] } })
                    }} />
                )}
            />
        </Flex>
        <FormPropertyRow>
            <Controller
                control={methods.control}
                name={name}
                render={({ field: { value: gradients, onChange } }) => (
                    <Flex flexDir='column' w='100%' gap='1rem'>
                        {gradients?.map((gradient: any, index: number) => {
                            return <Flex key={index} h='40px' gap='1.5rem'>
                                <ColorPickerInput id={`${id}-${index}`} colorPickerProps={{ hideColorTypeBtns: true, hideGradientType: true }}
                                    value={`linear-gradient(${gradientCoordinatesToDegrees(gradient.x1, gradient.x2, gradient.y1, gradient.y2)}deg, ${gradient.stops.map((s: any) => `${s.stopColor} ${s.offset}`).join(', ')})`}
                                    onChange={(e: any) => {
                                        const newGradients = [...gradients]
                                        const extractedGradient = extractGradientValues(e.target.value)
                                        if (extractedGradient) {
                                            newGradients[index] = { ...gradients[index], ...extractedGradient, legendSymbol: 'square', legendSymbolFillColor: 'black', legendSymbolTextColor: 'white' }
                                        }
                                        onChange({ target: { value: newGradients } })
                                    }}
                                    onDeleteColor={() => {
                                        const newGradients = [...gradients]
                                        newGradients.splice(index, 1)
                                        onChange({ target: { value: newGradients } })
                                    }}



                                    error={undefined} type="text" />
                                <Flex gap='0.5rem' h='100%'>
                                    <Box w='40px'>
                                        <SquareColorPickerInput id={`${id}-${index}-1`} colorPickerProps={{ hideColorTypeBtns: true }} value={gradient.legendSymbolFillColor} onChange={(e: any) => {
                                            const newGradients = [...gradients]
                                            newGradients[index].legendSymbolFillColor = e.target.value
                                            onChange({ target: { value: newGradients } })
                                        }
                                        } error={undefined} type="text" />
                                    </Box>
                                    {withLegendSymbolTextColor &&
                                        <Box w='40px' border='1px solid black'>
                                            <SquareColorPickerInput id={`${id}-${index}-2`} colorPickerProps={{ hideColorTypeBtns: true }} value={gradient.legendSymbolTextColor} onChange={(e: any) => {
                                                const newGradients = [...gradients]
                                                newGradients[index].legendSymbolTextColor = e.target.value
                                                onChange({ target: { value: newGradients } })
                                            }
                                            } error={undefined} type="text" />
                                        </Box>
                                    }
                                </Flex>
                            </Flex>
                        })}
                    </Flex>
                )}
            />
        </FormPropertyRow>
    </Flex>
}

export default GradientForm;