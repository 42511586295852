import { cardStyle, cartesianGrid, linearGradients, pallet, tooltipStyle, tooltipTextStyle } from "./common.js";
const defaultAreaChart = {
    properties: {
        style: {},
        component: {
            formatType: 'PERCENTAGE',
            cartesianGrid: cartesianGrid,
            linearGradients: linearGradients,
            pallet: pallet,
            tooltip: {
                style: tooltipStyle,
                textStyle: tooltipTextStyle
            }
        },
        cardStyle: cardStyle
    }
};
export const copyAreaChartStyles = (properties) => {
    const _properties = { ...properties };
    return _properties;
};
export default defaultAreaChart;
