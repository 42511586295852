import { Box } from '@chakra-ui/react';
import { useWindowInfo } from '@faceless-ui/window-info';
import { LayoutScale } from 'common/enums';
import { Layout } from 'common/types';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { SelectionProps } from 'src/blueprint/pages/editor/types';
import { fonts } from 'src/fonts';
import { assertIsDefined, parseViewportWidth } from '../utils';

interface CanvasProps {
    layout: Layout;
    children?: React.ReactNode;
}

interface ColumnsGrid {
    columns: number;
    type: 'stretch' | 'center' | 'left' | 'right';
    width: 'auto' | number;
    margin: number;
    gutter: number;
    color: string;
}

const Canvas: React.FC<CanvasProps> = ({ layout, children }) => {
    const [isDraggingSelectionBox, setIsDraggingSelectionBox] = useState(false);
    const [selectionBox, setSelectionBox] = useState<SelectionProps | null>(null);
    const measureRef = useRef<HTMLDivElement>(null);
    const [viewportWidth, setViewportWidth] = useState<number | undefined>(undefined);
    const windowInfo = useWindowInfo();
    const { actions, state, editorRef } = useCanvas();
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const isFixedLayout = layout?.scale !== LayoutScale.FILL;

    const grid = { size: 10, color: 'rgb(0, 255, 255, 20%)' };
    const columnsGrid: ColumnsGrid = {
        columns: 12,
        type: isFixedLayout ? 'center' : 'stretch',
        width: isFixedLayout ? 100 : 'auto',
        margin: isFixedLayout ? 0 : 300,
        gutter: 20,
        color: 'rgb(0, 255, 255, 20%)'
    };

    // measure viewport width before repaint
    useLayoutEffect(() => {
        if (measureRef.current) {
            const width = measureRef.current.getBoundingClientRect().width;
            setViewportWidth(width);
            if (!isFixedLayout) {
                actions.setScale(width / layout?.pageWidth);
            }
        }
        actions.setHeight(layout?.pageHeight);
        actions.setWidth(layout?.pageWidth);
    }, []);

    // measure viewport width after repaint
    useEffect(() => {
        if (windowInfo && editorRef.current) {
            const width = parseViewportWidth(windowInfo);
            setViewportWidth(width);
            if (!isFixedLayout) {
                actions.setScale(width / layout?.pageWidth);
            }
        }
    }, [windowInfo]);

    const boxWidth = useMemo(() => {
        if (state.useEditorReport) {
            return isFixedLayout ? layout?.pageWidth : layout?.pageWidth;
        }
        return isFixedLayout ? layout?.pageWidth : layout?.pageWidth * state.scale;
    }, [layout?.pageWidth, state.scale]);

    const boxHeight = useMemo(() => {
        if (state.useEditorReport) {
            return isFixedLayout ? layout?.pageHeight : layout?.pageHeight;
        }
        return isFixedLayout ? layout?.pageHeight : layout?.pageHeight * state.scale;
    }, [layout?.pageHeight, state.scale]);

    const pageWidth = boxWidth;
    const pageHeight = boxHeight;

    if (canvasRef.current && !state.showGrid) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = pageWidth;
        canvas.height = pageHeight;
        assertIsDefined(ctx);
        ctx.clearRect(0, 0, pageWidth, pageHeight);
    }

    useHotkeys(
        'Alt+G',
        () => state.useEditorReport && actions.setShowGrid(!state.showGrid),
        [state.showGrid]
    );

    useEffect(() => {
        const onClick = (event: MouseEvent) => {
            if ((event.target as Node) === canvasRef.current) {
                actions.setIsReadOnly(false);
                actions?.setEnabledInlineEditor(false);
                actions.setActiveSelection(new Set());
            }
        };

        document.addEventListener('pointerdown', onClick);

        return () => {
            document.removeEventListener('pointerdown', onClick);
        };
    }, []);

    useEffect(() => {
        if (canvasRef.current && state.showGrid) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            canvas.width = boxWidth;
            canvas.height = boxHeight;
            assertIsDefined(ctx);
            if (grid && state.showGrid) {
                const { size, color } = grid;
                const cols = Math.floor(pageWidth / size);
                const rows = Math.floor(pageHeight / size);
                ctx.lineWidth = 2;
                ctx.strokeStyle = color;
                ctx.beginPath();
                for (let i = 0; i < cols; i++) {
                    ctx.moveTo(i * size, 0);
                    ctx.lineTo(i * size, pageHeight);
                }
                for (let i = 0; i < rows; i++) {
                    ctx.moveTo(0, i * size);
                    ctx.lineTo(pageWidth, i * size);
                }
                ctx.stroke();
            }
            if (columnsGrid && state.showGrid) {
                const { columns, type, width, margin, gutter, color } = columnsGrid;
                ctx.fillStyle = color;
                if (type === 'stretch') {
                    const columnWidth =
                        width === 'auto'
                            ? (pageWidth - 2 * margin - (columns - 1) * gutter) / columns
                            : pageWidth;
                    for (let i = 0; i < columns; i++) {
                        ctx.fillStyle = color;
                        ctx.fillRect(
                            i * (columnWidth + gutter) + margin,
                            0,
                            columnWidth,
                            pageHeight
                        );
                    }
                } else if (type === 'center') {
                    const columnWidth =
                        width === 'auto'
                            ? (pageWidth - 2 * margin - (columns - 1) * gutter) / columns
                            : width;
                    const offset = (pageWidth - columns * (columnWidth + gutter)) / 2;
                    for (let i = 0; i < columns; i++) {
                        ctx.fillStyle = color;
                        ctx.fillRect(
                            i * (columnWidth + gutter) + offset,
                            0,
                            columnWidth,
                            pageHeight
                        );
                    }
                } else if (type === 'left') {
                    const columnWidth =
                        width === 'auto'
                            ? (pageWidth - 2 * margin - (columns - 1) * gutter) / columns
                            : width;
                    const offset = gutter;
                    for (let i = 0; i < columns; i++) {
                        ctx.fillStyle = color;
                        ctx.fillRect(
                            i * (columnWidth + gutter) + offset,
                            0,
                            columnWidth,
                            pageHeight
                        );
                    }
                } else if (type === 'right') {
                    const columnWidth =
                        width === 'auto'
                            ? (pageWidth - 2 * margin - (columns - 1) * gutter) / columns
                            : width;
                    const offset = pageWidth - columns * (columnWidth + gutter);
                    for (let i = 0; i < columns; i++) {
                        ctx.fillStyle = color;
                        ctx.fillRect(
                            i * (columnWidth + gutter) + offset,
                            0,
                            columnWidth,
                            pageHeight
                        );
                    }
                }
            }
        }
    }, [pageHeight, pageWidth, grid, columnsGrid, state.showGrid, boxWidth, boxHeight]);

    const allFonts = useMemo(() => {
        return Object.keys(fonts).map((key) => fonts[key as keyof typeof fonts]);
    }, [fonts]);

    const handleMouseDown = (e: any) => {
        if (state.activeSelection.size > 0) return;

        if (!state.useEditorReport) return;

        setIsDraggingSelectionBox(true);
        const editor = editorRef.current?.getBoundingClientRect();
        const startX = e.clientX - editor!.left;
        const startY = e.clientY - editor!.top;
        setSelectionBox({ startX, startY });
    };

    const handleMouseMove = (e: any) => {
        if (!isDraggingSelectionBox || !selectionBox) return;
        const editor = editorRef.current?.getBoundingClientRect();
        const currentX = e.clientX - editor!.left;
        const currentY = e.clientY - editor!.top;

        setSelectionBox((prev) => ({
            ...prev!,
            currentX,
            currentY
        }));
    };

    const calculateBoxStyle = (box: SelectionProps | null) => {
        if (!box || !box.currentX || !box.currentY) return null;

        const { startX, startY, currentX, currentY } = box;

        return {
            left: Math.min(startX, currentX),
            top: Math.min(startY, currentY),
            width: Math.abs(currentX - startX),
            height: Math.abs(currentY - startY)
        };
    };

    const boxStyle = calculateBoxStyle(selectionBox);

    const handleMouseUp = () => {
        if (!isDraggingSelectionBox || !selectionBox) return;
        setIsDraggingSelectionBox(false);

        const boxProps = calculateBoxStyle(selectionBox);

        if (!boxProps) return;

        const selected = state.canvasObjects.filter((obj) =>
            isIntersecting(
                {
                    startX: boxProps!.left,
                    startY: boxProps!.top,
                    currentX: boxProps!.width,
                    currentY: boxProps!.height
                },
                {
                    startX: obj.x,
                    startY: obj.y,
                    currentX: obj.w,
                    currentY: obj.h
                }
            )
        );
        actions.setActiveSelection(new Set(selected.map((obj) => obj.id)));
        setSelectionBox(null);
    };

    const isIntersecting = (rect1: SelectionProps, rect2: SelectionProps) => {
        return !(
            rect1.startX > rect2.startX + rect2.currentX! ||
            rect1.startX + rect1.currentX! < rect2.startX ||
            rect1.startY > rect2.startY + rect2.currentY! ||
            rect1.startY + rect1.currentY! < rect2.startY
        );
    };

    return (
        <React.Fragment>
            {allFonts.map((font) => {
                const fontElement = font.renderHelmet();
                return React.cloneElement(fontElement, { key: font.name });
            })}
            <div
                ref={measureRef}
                style={{
                    width: '100%',
                    height: pageHeight,
                    display: viewportWidth ? 'none' : 'block'
                }}
            />
            {viewportWidth && (
                <Box
                    ref={editorRef}
                    boxShadow="rgba(0, 0, 0, 0.26) 0px 1px 4px;"
                    w="max-content"
                    margin={isFixedLayout ? 'auto' : 'unset'}
                    position={'relative'}
                    fontFamily={layout?.fontFamily ?? 'Roboto'}
                    userSelect={isDraggingSelectionBox ? 'none' : 'auto'}
                >
                    <Box
                        w={boxWidth}
                        h={boxHeight}
                        background={layout?.background}
                        position="relative"
                        boxSizing="content-box"
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                    >
                        <canvas
                            ref={canvasRef}
                            style={{ position: 'absolute', zIndex: 0 }}
                            id="canvas"
                        />
                        {boxStyle && boxStyle.width > 10 && boxStyle.height > 10 && (
                            <Box
                                position="absolute"
                                border="1px solid blue"
                                backgroundColor="rgba(0, 0, 255, 0.2)"
                                zIndex={10000}
                                {...boxStyle}
                            />
                        )}
                        {children}
                    </Box>
                </Box>
            )}
        </React.Fragment>
    );
};

export default Canvas;
